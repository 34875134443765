
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class CouncilService {

    councilApi = `${this.apiConstants.serviceEndpoint}/Council`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getCouncil(id: string): Observable<any> {
        return this.http.get(`${this.councilApi}/get-council/${id}`);
    }

    getCouncils(body: any): Observable<any> {
        return this.http.post(`${this.councilApi}/get-councils`, body);
    }

    createCouncil(body: any) {
        return this.http.post(`${this.councilApi}/create-council`, body);
    }

    updateCouncil(body: any) {
        return this.http.put(`${this.councilApi}/update-council/${body.id}`, body);
    }

    deleteCouncil(id: string): Promise<any> {
        return this.http.delete(`${this.councilApi}/delete-council/${id}`).toPromise();
    }

    deleteCouncils(body: string[]): Promise<any> {
        return this.http.post(`${this.councilApi}/delete-councils`, body).toPromise();
    }

    getAvailableCouncilMembers(id?: string): Observable<any> {
        return this.http.get(`${this.councilApi}/get-available-council-members/${id ? id : ''}`);
    }

    getCouncilsByReviewPeriodId(id: string): Observable<any> {
        return this.http.get(`${this.councilApi}/get-councils-by-review-period-id/${id}`);
    }


    getAllCouncilTypes(email: string): Observable<any> {
        return this.http.get(`${this.councilApi}/get-all-council-types/${email}`);
    }

    CheckMarkWithMember(email: string, councilId: string): Observable<any> {
        return this.http.get(`${this.councilApi}/check-member-display/${email}/${councilId}`);
    }

    getCouncilsByUserId(userId?: string): Observable<any> {
      let params;
      if (userId) {
        params = { userId }
      }
      return this.http.get(
        `${this.councilApi}/get-by-user-id`,
        { params }
      );
    }
}
