<div class="ctm-select">
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="example-tree"
  >
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      {{ node.value?.businessName }}
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.value?.businessName"
          (click)="treeControl.expandFlattenedNodes(node)"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
        {{ node.value?.businessName }}
      </div>
      <div
        [class.example-tree-invisible]="!treeControl.isExpanded(node)"
        role="group"
      >
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</div>
