
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
  providedIn: "root"
})
export class GeneralSharedService {

  constructor(
    @Inject(API_TOKEN) private apiConstants: ApiConstant,
    private http: HttpClient
  ) { }

  getAllReviewPeriods(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/ReviewPeriod/get-all-review-periods`);
  }

  getAllCouncils(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Council/get-all-councils`);
  }

  getAllCouncilTypes(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Council/get-council-types`);
  }

  getAllCriterias(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Criteria/get-all-criterias`);
  }

  getAllDistricts(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/District/get-all-districts`);
  }

  getAllGroups(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Group/get-all-groups`);
  }

  getAllGroupRoles(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/GroupRole/get-all-group-roles`);
  }

  getAllInitiatives(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Initiative/get-all-initiatives`);
  }

  getAllInitiativeFields(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/InitiativeField/get-all-initiative-fields`);
  }

  getAllInitiativeMembers(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/InitiativeMember/get-all-initiative-members`);
  }

  getAllMajors(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Major/get-all-majors`);
  }

  getAllOfficeDocuments(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/OfficeDocument/get-all-office-documents`);
  }

  getAllOfficeRoles(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/OfficeRole/get-all-office-roles`);
  }

  getAllOrganizations(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Organization/get-all-organizations`);
  }

  getAllPortalMenus(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/SystemFunction/get-all-system-functions`);
  }

  getAllPositions(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Position/get-all-positions`);
  }

  getAllProvinces(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Province/get-all-provinces`);
  }

  getAllRoles(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Role/get-all-roles`);
  }

  getAllUsers(): Observable<any> {
    return this.http.get(`${this.apiConstants.endpoint}/api/User/get-all-users`);
  }

  getAllUserGroups(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/UserGroup/get-all-user-groups`);
  }

  getAllUserRoles(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/UserRole/get-all-user-roles`);
  }

  getAllVillages(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Village/get-all-villages`);
  }

  getAllWorkingYears(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/WorkingYear/get-all-working-years`);
  }

  // TODO: change this to get all xếp loại
  getAllClassifications(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Initiative/get-all-classifications`);
  }

  // TODO: change this to get all qualification type from backend
  getAllQualifications(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/InitiativeMember/get-all-qualifications`);
  }

  // TODO: change this to get all InitiativeTypes
  getAllInitiativeTypes(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Initiative/get-all-initiative-types`);
  }

  getAllInitiativeStatus(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Initiative/get-all-initiative-status`);
  }

  // TODO: get the role group types from backend
  getAllRoleGroupTypes(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/get-all-role-group-types`);
  }

  // TODO: get all role types from backend
  getAllRoleTypes(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/get-all-role-types`);
  }

  // TODO: lấy danh sách giớ tính từ back end hoặc tạo enum ở front end
  getAllGenderTypes(): Observable<any> {
    return this.http.get(`${this.apiConstants.endpoint}/api/User/get-all-gender-types`);
  }
  getAllUserStatuss(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/get-all-user-statuss`);
  }

  getAllAnnouncements(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Announcement/get-all-announcements`);
  }

  getAllAppSettings(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/AppSetting/get-all-app-settings`);
  }

  getAllInitiativeDocuments(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/InitiativeDocument/get-all-initiative-documents`);
  }


  getAllAnnouncementTypes(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/get-all-announcement-types`);
  }

  getDistricts(body: any): Observable<any> {
    return this.http.post(`${this.apiConstants.serviceEndpoint}/District/get-districts`, body);
  }

  getMembers(body: any): Observable<any> {
    return this.http.post(`${this.apiConstants.serviceEndpoint}/InitiativeMember/get-initiative-members`, body);
  }

  getMembersByInitiativeId(id: string): Observable<any> {
    return this.getMembers({
      getAll: true,
      filters: [
        {
          propertyNames: ['initiativeId'],
          propertyValue: id ?? null
        }
      ]
    })
  }

  getDistrictsByProvinceId(id: string): Observable<any> {
    return this.getDistricts({
      getAll: true,
      filters: [
        {
          propertyNames: ['provinceId'],
          propertyValue: id ?? null
        }
      ]
    })
  }

  getVillages(body: any): Observable<any> {
    return this.http.post(`${this.apiConstants.serviceEndpoint}/Village/get-villages`, body);
  }

  getVillagesByDistrictId(id: string): Observable<any> {
    return this.getVillages({
      getAll: true,
      filters: [
        {
          propertyNames: ['districtId'],
          propertyValue: id ?? null
        }
      ]
    })
  }

  getNotifications(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/AnnouncementUser/get-all-announcement-users`);
  }
  
  getAllOrganizationsWithParent(): Observable<any> {
    return this.http.get(`${this.apiConstants.serviceEndpoint}/Organization/get-all-organizations-with-parent`);
  }
}

/*
export * from './general-shared.service';
export * from './district.service';
export * from './group.service';
export * from './group-role.service';
export * from './initiative.service';
export * from './initiative-field.service';
export * from './initiative-member.service';
export * from './major.service';
export * from './office-document.service';
export * from './office-role.service';
export * from './organization.service';
export * from './portal-menu.service';
export * from './position.service';
export * from './province.service';
export * from './role.service';
export * from './user.service';
export * from './user-group.service';
export * from './user-role.service';
export * from './village.service';
export * from './working-year.service';

*/

