
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class GroupRoleService {

    groupRoleApi = `${this.apiConstants.serviceEndpoint}/GroupRole`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getGroupRole(id: string) : Observable<any>{
        return this.http.get(`${this.groupRoleApi}/get-group-role/${id}`);
    }

    getGroupRoles(body: any) : Observable<any>{
        return this.http.post(`${this.groupRoleApi}/get-group-roles`, body);
    }

    createGroupRole(body: any) {
        return this.http.post(`${this.groupRoleApi}/create-group-role`, body);
    }

    updateGroupRole(body: any) {
        return this.http.put(`${this.groupRoleApi}/update-group-role/${body.id}`, body);
    }

    deleteGroupRole(id: string): Promise<any> {
        return this.http.delete(`${this.groupRoleApi}/delete-group-role/${id}`).toPromise();
    }

    deleteGroupRoles(body: string[]): Promise<any> {
        return this.http.post(`${this.groupRoleApi}/delete-group-roles`, body).toPromise();
    }
}
