import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "..";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  user: any = {};
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.router.navigate(['/trang-chu']);
  }

  Logout() {
    this.user = this.authService.logout();
  }

  redirectToLink(link: string) {
    this.router.navigate([link]);
  }
}
