
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class OfficeRoleService {

    officeRoleApi = `${this.apiConstants.serviceEndpoint}/OfficeRole`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getOfficeRole(id: string) : Observable<any>{
        return this.http.get(`${this.officeRoleApi}/get-office-role/${id}`);
    }

    getOfficeRoles(body: any) : Observable<any>{
        return this.http.post(`${this.officeRoleApi}/get-office-roles`, body);
    }

    createOfficeRole(body: any) {
        return this.http.post(`${this.officeRoleApi}/create-office-role`, body);
    }

    updateOfficeRole(body: any) {
        return this.http.put(`${this.officeRoleApi}/update-office-role/${body.id}`, body);
    }

    deleteOfficeRole(id: string): Promise<any> {
        return this.http.delete(`${this.officeRoleApi}/delete-office-role/${id}`).toPromise();
    }

    deleteOfficeRoles(body: string[]): Promise<any> {
        return this.http.post(`${this.officeRoleApi}/delete-office-roles`, body).toPromise();
    }
}
