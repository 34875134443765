import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services";

@Component({
  selector: "auth-register-form",
  templateUrl: "./auth-register-form.component.html",
  styleUrls: ["./auth-register-form.component.scss"],
})
export class AuthRegisterFormComponent implements OnInit {
  registerForm: FormGroup;
  isShowPassword: boolean = false;
  isShowRePassword: boolean = false;
  passwordLastDes: string =
    'At least one Special Character " !"#$%&' +
    "'" +
    "()*+,-./:;<=>?@[]^_`{|}~";
  registerSuccess: boolean = false;

  codeActive: string;
  userId: string;
  activeSuccess: boolean = false;

  registerError$ = this.authService.registerError$;
  activeError$ = this.authService.activeError$;

  isLoading: boolean = false;
  showResultActive: boolean =  false;
  oldValue: string = null;

  constructor(
    private fb: FormBuilder, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    ) {}

  ngOnInit() {
    if(this.router.url.indexOf("active-store") != -1){
      this.isLoading = true;
      this.activatedRoute.queryParams.subscribe(params => {
        this.userId = params['id'];
        this.codeActive = params['code'];
        const req = {
          id: this.userId,
          code: this.codeActive,
        };
        this.authService.activeErrorSub$.next(null);
        this.authService.activeAccount(req).subscribe((res: any) => {
          this.showResultActive = true;
          this.isLoading = false;
          this.cd.detectChanges();
          if(res.statusCode == 200){
            this.activeSuccess = true;
            this.cd.detectChanges();
            return this.openSnackBar("Active success");
          }
          else {
            this.activeSuccess = false;
            this.cd.detectChanges();
            return this.openSnackBar(res.message, "FAIL");
          }
        });
      });
    }
    else {
      this.initForm();
    }
    if(this.registerForm && this.registerForm.controls){
      this.registerForm.valueChanges.subscribe((_res: any) => {
        if (this.registerForm.value.username && this.registerForm.value.username.length > 0 && (this.oldValue == null || this.registerForm.value.username != this.oldValue)) {
          let val = this.registerForm.value.username.split(' ').join('').toLowerCase();
          this.oldValue = val;
          this.setValueField("username", val);
        }
      });
    }
  }

  initForm() {
    this.registerForm = this.fb.group({
      email: ["", Validators.compose([Validators.required, 
        Validators.pattern(
          /^[a-z]+[a-z0-9._]+@[a-z_-]+\.[a-z.]{2,5}$/
        ),])
      ],
      username: ["", Validators.compose([Validators.required])],
      password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(?=.{8,}$)(?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/
          ),
        ]),
      ],
      rePassword: ["", Validators.compose([Validators.required])],
    });
  }

  setValueField(field: string, value: any) {
    this.registerForm.controls[field].setValue(value);
  }

  get password() {
    return this.registerForm.controls.password as FormControl;
  }

  get email() {
    return this.registerForm.controls.email as FormControl;
  }

  validateForm(controlName: string, validationType: string): boolean {
    if (controlName == "rePassword" && validationType == "rePassword") {
      const formData = this.registerForm.controls;
      if(formData.rePassword.value && formData.rePassword.value != "" && formData.password.value != formData.rePassword.value){
        return true;
      }
      else {
        return false;
      }
    }
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  trimValue(key: string) {
    let trimData = this.registerForm.controls[key].value;
    if (trimData) {
      trimData = trimData.trim();
    }
    this.registerForm.controls[key].setValue(trimData);
  }

  register() {
    if (!this.registerForm.valid) {
      this.registerForm.markAllAsTouched();
      return;
    }
    const formData = this.registerForm.controls;
    const req = {
      email: formData.email.value,
      username: formData.username.value,
      password: formData.password.value,
    };
    this.isLoading = true;
    this.authService.registerErrorSub$.next(null);
    this.authService.register(req).subscribe((res: any) => {
      this.isLoading = false;
      this.cd.detectChanges();
      if(res.statusCode == 200){
        this.registerSuccess = true;
        this.cd.detectChanges();
        return this.openSnackBar("Register success");
      }
      else {
        return this.openSnackBar(res.message, "FAIL");
      }
    });
  }

  goToHome() {
    this.router.navigate(["/"]);
  }

  goToLogin() {
    this.router.navigate(["/auth/login"]);
  }

  openSnackBar(message: string, status: string = "SUCCESS") {
    let className =
      status == "SUCCESS"
        ? "messenger-success"
        : status == "WANRING"
        ? "messenger-waning"
        : "messenger-fail";

    this._snackBar.open(message, "", {
      horizontalPosition: "end",
      verticalPosition: "top",
      duration: 3000,
      panelClass: className,
    });
  }
}
