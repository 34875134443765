import { Injectable } from "@angular/core";
import { GeneralSharedService } from "../../shared/services";
import { mapRoute } from "../constants/map-route";
import { isAuthorized, getJWT, getRole } from "../../auth/services/jwt-helper";
import { orderBy } from "lodash";

@Injectable({
  providedIn: 'root',
})
export class MapRouteService {
  keyStorageMenus = 'menus'
  portalMenus: any[] = [];
  menus: any[] = [];
  treeMenus: any[] = [];
  private buildSuccess: boolean = false;
  building: boolean = false;
  intervalGetTreeMenus: any;
  intervalGetMenus: any;
  constructor(
    private generalSharedService: GeneralSharedService,
  ) {
  }

  initialize(forceRequest?: boolean) {
    this.building = true;
    if (forceRequest) {
      this.buildSuccess = false;
    }
    const menusString = localStorage.getItem(this.keyStorageMenus);
    if (!this.buildSuccess || !menusString) {
      this.request();
    } else {
      const allTypeMenus = JSON.parse(menusString);
      this.portalMenus = allTypeMenus.portalMenus;
      this.menus = allTypeMenus.menus;
      this.treeMenus = allTypeMenus.treeMenus;
      this.buildSuccess = true;
      this.building = false;

    }
  }


  request() {
    this.generalSharedService.getAllPortalMenus().subscribe(res => {
      this.portalMenus = res;
      this.buildMenus();
      this.buildSuccess = true;
      const allTypeMenus = {
        portalMenus: this.portalMenus,
        menus: this.menus,
        treeMenus: this.treeMenus,
      }
      localStorage.removeItem(this.keyStorageMenus);
      localStorage.setItem(this.keyStorageMenus, JSON.stringify(allTypeMenus));
      this.building = false;
    }, (_) => {
      this.buildSuccess = false;
      this.request();
    });
  }

  clearBuildedMenus() {
    localStorage.removeItem(this.keyStorageMenus);
  }

  getTreeMenus(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (this.buildSuccess) {
        resolve(this.treeMenus);
      } else {
        this.initialize();
        this.intervalGetTreeMenus = setInterval(() => {
          if (this.buildSuccess) {
            clearInterval(this.intervalGetTreeMenus);
            resolve(this.treeMenus);
          }
        }, 1000);
      }

    });
  }

  getMenus(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (this.buildSuccess) {
        resolve(this.menus);
      } else {
        this.initialize();
        this.intervalGetMenus = setInterval(() => {
          if (this.buildSuccess) {
            clearInterval(this.intervalGetMenus);
            resolve(this.menus);
          }
        }, 1000);
      }

    });
  }

  pathJoin(parts: string[], separator: string = '/'): string {
    var replace = new RegExp(separator + '{1,}', 'g');
    return parts.join(separator).replace(replace, separator);
  }

  buildMenus() {
    this.menus = [];
    this.portalMenus.forEach(portalMenu => {
      const menu = {
        ...portalMenu,
        children: [],
        fullViewPath: this.pathJoin(this.getViewPath(portalMenu)).toLowerCase(),
        ableToView: this.checkPermission(portalMenu.functionCode, 'XEM')
      }
      this.menus.push(menu);
    });

    this.menus.forEach(x => {
      x.children = this.menus.filter(s => s.parentId === x.id);
    });

    this.treeMenus = this.menus.filter(x => x.parentId === null);
    this.treeMenus.forEach((item) => {
      if (item.functionCode === "BaoCao") {
        item.children = orderBy(item.children, 'functionName');
      }
    })
  }

  getViewPath(menu: any): string[] {
    let currentViewPath = '/' + menu.viewPath;
    let result = [currentViewPath];
    if (menu.parentId) {
      const parentMenu = this.portalMenus.find(s => s.id === menu.parentId);
      result = this.getViewPath(parentMenu).concat(result);
    }
    return result;
  }

  getPathByFunctionCode(functionCode: string): string {
    if (this.menus) {
      return this.menus.find(s => s.functionCode === functionCode).fullViewPath;
    } else {
      return mapRoute.find(s => s.functionCode === functionCode).fixedPath;
    }
  }

  getPathOfReviewInitiative(): string {
    return this.getPathByFunctionCode('XetDuyet');
  }

  getPathOfRegisterInitiative(): string {
    return this.getPathByFunctionCode('DonDangKy');
  }

  getPathOfInitiative(path: string): string {
    return this.getPathByFunctionCode(path);
  }

  checkPermission(permission: any, action: string, objCode?: any, id?: any) {
    const key = permission.toUpperCase() + '-' + action
    return isAuthorized(key, objCode, id);
  }
}
