<div class="ctm-select">
  <div
    (click)="openOptions($event)"
    class="ctm-select__inner"
    [style.border-radius]="isOpen ? '18px 18px 0 0' : '18px'"
  >
		<div>
			<div class="ctm-select__icon">
				<img [src]="'assets/images/' + currentOption.image" alt="" />
			</div>
			<div class="ctm-select__text">
				{{ currentOption.text }}
			</div>
			<div class="ctm-select__status">
				{{ currentOption.default ? '(Default)' : '' }}
			</div>
		</div>
    <div class="ctm-select__suffix">
      <img src="assets/images/chevron-sort-down.png" alt="" />
    </div>
  </div>
  <div *ngIf="isOpen" class="ctm-select__options" #optionsEl>
    <div *ngFor="let option of options" (click)="onSelect($event, option.key)" class="ctm-select__option-item">
      <div class="ctm-select__icon">
        <img [src]="'assets/images/' + option.image" alt="" />
      </div>
      <div class="ctm-select__text">
        {{ option.text }}
      </div>
      <div class="ctm-select__status">
        {{ option.default ? '(Default)' : '' }}
      </div>
    </div>
  </div>
</div>
