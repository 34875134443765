
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class DistrictService {

    districtApi = `${this.apiConstants.serviceEndpoint}/District`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getDistrict(id: string) : Observable<any>{
        return this.http.get(`${this.districtApi}/get-district/${id}`);
    }

    getDistricts(body: any) : Observable<any>{
        return this.http.post(`${this.districtApi}/get-districts`, body);
    }

    createDistrict(body: any) {
        return this.http.post(`${this.districtApi}/create-district`, body);
    }

    updateDistrict(body: any) {
        return this.http.put(`${this.districtApi}/update-district/${body.id}`, body);
    }

    deleteDistrict(id: string): Promise<any> {
        return this.http.delete(`${this.districtApi}/delete-district/${id}`).toPromise();
    }

    deleteDistricts(body: string[]): Promise<any> {
        return this.http.post(`${this.districtApi}/delete-districts`, body).toPromise();
    }
}
