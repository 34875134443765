<div class="example-sidenav-content">
  <button type="button" mat-button (click)="showHide()">
    <mat-icon>filter_list</mat-icon>
    Tìm kiếm
  </button>
</div>

<mat-drawer-container
  #panel
  class="example-container show-filter-{{ showFiller }}"
  autosize
>
  <mat-drawer #drawer class="example-sidenav" mode="side">
    <div class="" *ngIf="showFiller">
      <div class="title">
        <span class="title-text">Tìm kiếm</span>
        <mat-icon class="title-icon" (click)="close()">close</mat-icon>
      </div>
      <div class="body">
        <ng-content></ng-content>
      </div>
      <div class="footer">
        <button type="button" class="btn-cancel" mat-button (click)="reset()">
          Làm mới
        </button>
        <button
          type="button"
          class="btn-primary"
          style="margin-left: 16px;"
          mat-button
          (click)="search()"
        >
          Tìm
        </button>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
