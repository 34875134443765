<div class="main-modal h-100 d-flex flex-column">
  <div class="login-header cursor-pt">
    <div class="logo-area col-12">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="main-logo">
          <img src="assets/images/main-logo.png" />
        </div>
        <div>
          <div class="name">
            PHẦN MỀM QUẢN LÝ SÁNG KIẾN KINH NGHIỆM THÀNH PHỐ HÀ NỘI
          </div>
          <div class="slogan">SỞ KHOA HỌC VÀ CÔNG NGHỆ</div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-verify-code">
    <div class="block-verify-code">
      <div class="wrapper">
        <div class="back-btn" (click)="goback()">
          <span>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#000000"
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
              />
              <path
                fill="#000000"
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
              />
            </svg>
          </span>
          <span style="margin-left: 8px;">Trở lại</span>
        </div>
        <ng-container *ngIf="!isSend">
          <svg
            width="160px"
            height="160px"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <style>
                .cls-1 {
                  fill: none;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 0.5px;
                }
              </style>
            </defs>
            <g data-name="40-mail" id="_40-mail">
              <polyline class="cls-1" points="1 27 1 5 31 5" stroke="#000000" />
              <line
                class="cls-1"
                x1="1"
                x2="13"
                y1="5"
                y2="17"
                stroke="#000000"
              />
              <polyline
                class="cls-1"
                points="12 16 1 27 12 27"
                stroke="#000000"
              />
              <polyline
                class="cls-1"
                points="24 12 31 5 31 15"
                stroke="#000000"
              />
              <circle class="cls-1" cx="23" cy="23" r="8" stroke="red" />
              <line
                class="cls-1"
                x1="26"
                x2="20"
                y1="20"
                y2="26"
                stroke="red"
              />
              <line
                class="cls-1"
                x1="26"
                x2="20"
                y1="26"
                y2="20"
                stroke="red"
              />
            </g>
          </svg>
          <h3>Xác thực email của bạn</h3>
          <p>Gửi một mã xác thực tới email {{ user.email }}</p>
          <div *ngIf="errorMsg" class="err-msg" [class.visibility]="!!errorMsg">
            {{ errorMsg }}
          </div>
          <div class="block-submit">
            <button
              class="btn-submit"
              style="margin: auto;"
              (click)="sendCode()"
            >
              Gửi mã
            </button>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="isSend">
        <svg
          width="160px"
          height="160px"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <style>
              .cls-1 {
                fill: none;
                stroke: #000000;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 0.5px;
              }
            </style>
          </defs>

          <g data-name="37-mail" id="_37-mail">
            <polyline class="cls-1" points="31 9 31 31 1 31 1 9" />

            <polyline class="cls-1" points="6 4 1 9 16 24 31 9 26 4" />

            <line class="cls-1" x1="1" x2="12" y1="31" y2="20" />

            <line class="cls-1" x1="31" x2="20" y1="31" y2="20" />

            <polyline class="cls-1" points="6 14 6 1 26 1 26 14" />

            <line class="cls-1" x1="10" x2="22" y1="5" y2="5" />

            <line class="cls-1" x1="10" x2="22" y1="9" y2="9" />

            <line class="cls-1" x1="10" x2="22" y1="13" y2="13" />
          </g>
        </svg>
        <h3>Chúng tôi đã gửi một email tới cho bạn</h3>
        <p>Kiểm tra email {{ user.email }} và nhập mã nhận được vào bên dưới</p>
        <div class="text-center" style="margin-bottom: 5rem;">
          <div id="otp" class="d-flex justify-content-center">
            <input
              class="m-2 text-center form-control form-control-solid shadow-none"
              type="text"
              id="0"
              maxlength="6"
              autocomplete="off"
              [(ngModel)]="verificatioCode"
            />
          </div>
          <div *ngIf="errorMsg" class="err-msg" [class.visibility]="!!errorMsg">
            {{ errorMsg }}
          </div>
        </div>
        <div class="block-submit" *ngIf="isSend">
          <div class="text-send-code">
            Có thể mất một vài phút để nhận được mã xác thực. Chưa nhận được?
            <span class="pointer" (click)="sendCode()">
              Gửi lại mã
              <ng-container *ngIf="countTime">
                {{ " sau " + timeout + "s" }}</ng-container
              >
            </span>
          </div>
          <button class="btn-submit" (click)="verifyCode()">Xác thực</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
