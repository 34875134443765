import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";

@Component({
  selector: "app-custom-select",
  templateUrl: "./custom-select.component.html",
  styleUrls: ["./custom-select.component.scss"]
})
export class CustomSelectComponent implements OnInit, OnDestroy {
  @Input() options: any[] = [];

  @Input() selectedOption: string;

	@Output() select = new EventEmitter<any>();

	currentOption: any;

  isOpen = false;
	@ViewChild('optionsEl') optionsEl: ElementRef<HTMLDivElement>;

	constructor() {}

  ngOnInit(): void {
		this.currentOption = this.options.find(option => option.image === this.selectedOption) ?? this.options[0];
		window.addEventListener('click', this.handleClick);
  }

	ngOnChanges() {
		this.currentOption = this.options.find(option => option.image === this.selectedOption) ?? this.options[0];
	}


	ngOnDestroy(): void {
		window.removeEventListener('click', this.handleClick);
	}

	handleClick = (e: MouseEvent) => {
		if (!this.isOpen) return;
		if (!this.optionsEl.nativeElement.contains(e.target as Node)) {
			this.isOpen = false;
		}
	}

	openOptions(e: any) {
		e.preventDefault();
		e.stopPropagation();
		this.isOpen = !this.isOpen;
	}

	onSelect(e: any, key: string) {
		e.preventDefault();
		e.stopPropagation();
		this.currentOption = this.options.find(option => option.key === key) ?? this.options[0];
		this.isOpen = false;
		this.select.emit(this.currentOption);
	}
}
