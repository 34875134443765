import { SnackBarType } from './../constants/snack-bar-type';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class CustomSnackBarService {
    private openSnackBar(message: string, type: string) {
        this._snackBar.open(message, '', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 3000,
            panelClass: type,
        });
    }

    constructor(private _snackBar: MatSnackBar) { }

    error(message: string) {
        this.openSnackBar(message, SnackBarType.FAIL);
    }

    success(message: string) {
        this.openSnackBar(message, SnackBarType.SUCCESS);
    }

    
    warning(message: string) {
        this.openSnackBar(message, SnackBarType.WANING);
    }
}
