
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class CriteriaService {

    criteriaApi = `${this.apiConstants.serviceEndpoint}/Criteria`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getCriteria(id: string) : Observable<any>{
        return this.http.get(`${this.criteriaApi}/get-criteria/${id}`);
    }

    getCriterias(body: any) : Observable<any>{
        return this.http.post(`${this.criteriaApi}/get-criterias`, body);
    }

    createCriteria(body: any) {
        return this.http.post(`${this.criteriaApi}/create-criteria`, body);
    }

    updateCriteria(body: any) {
        return this.http.put(`${this.criteriaApi}/update-criteria/${body.id}`, body);
    }

    deleteCriteria(id: string): Promise<any> {
        return this.http.delete(`${this.criteriaApi}/delete-criteria/${id}`).toPromise();
    }

    deleteCriterias(body: string[]): Promise<any> {
        return this.http.post(`${this.criteriaApi}/delete-criterias`, body).toPromise();
    }
}
