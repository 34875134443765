<div class="main-modal">
    <div class="mx-auto flex-grow-1 mid-login">
        <div class="mid-heading">
            <a (click)="dialogRef.close(0)" class="close-modal position-absolute" data-dismiss="modal">
                <i class="icon-close"></i>
            </a>
            <h3 class="mid-title">Please Login</h3>
            <span class="mid-sub-title">Login to manage your account</span>
        </div>
        <div class="user-form login-form pb-5">
            <div [formGroup]="loginForm">
                <div class="form-shadow mx-4 mx-xl-0">
                    <div class="form-group-custom">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-icon"><i class="icon-mail"></i></div>
                            </div>
                            <div class="input-group-control flex-grow-1">
                                <label>Email address</label>
                                <input formControlName="email" type="text" class="form-control" name="email"
                                    placeholder="Your Email address" (blur)="trimValue('email')" />
                            </div>
                        </div>
                        <mat-error *ngIf="validateForm('email', 'required')">
                            The email field is required
                        </mat-error>
                        <mat-error *ngIf="validateForm('email', 'email')">
                            This is not a valid email
                        </mat-error>
                    </div>
                    <div class="form-group-custom form-group-password mb-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-icon"><i class="icon-key"></i></div>
                            </div>
                            <div class="input-group-control flex-grow-1">
                                <label>Password</label>
                                <input formControlName="password" [type]="isShowPassword ? 'text' : 'password'"
                                    class="form-control" name="password" placeholder="Password" />
                            </div>
                            <div class="input-group-append">
                                <div class="input-group-icon toggle-password">
                                    <i (click)="this.isShowPassword = !this.isShowPassword"
                                        class="{{ isShowPassword ? 'icon-eye-off' : 'icon-eye' }}"></i>
                                </div>
                            </div>
                        </div>
                        <mat-error *ngIf="validateForm('password', 'required')">
                            The password field is required
                        </mat-error>
                    </div>
                    <div class="form-action d-flex justify-content-between align-items-center px-4 mt-lg-4 mt-3 mb-3">
                        <div class="form-check pl-0">
                            {{ loginError$ | async }}
                        </div>
                    </div>
                </div>
                <div class="form-action d-flex justify-content-between align-items-center px-4 mt-lg-4 mt-3 mb-3">
                    <div class="form-check pl-0">
                        <input type="checkbox" class="form-check-input form-check-input--minimal" id="keep-login"
                            name="keep-login" />
                        <label class="form-check-label" for="keep-login">Stay loged in</label>
                    </div>
                    <div class="form-link mt-0">
                        <a (click)="forgotPassForm()" class="cursor-pt forgot-password">Forgot your password?</a>
                    </div>
                </div>
                <div
                    class="form-button d-flex flex-column flex-lg-row flex-column-reverse justify-content-between align-items-center">
                    <div class="form-link mt-4 mb-4 mt-lg-0 mb-lg-0 pr-lg-4 pl-3">
                        <a (click)="registerForm()" class="font-weight-normal accent-color link-register cursor-pt">
                            Don’t have an account?
                            <strong>Register Now</strong>
                        </a>
                    </div>
                    <button (click)="login()" class="mid-button" [class.disabled]="isLoading">LOGIN</button>
                </div>
            </div>
        </div>
    </div>
</div>