import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var $: any;

@Component({
    selector: 'app-dialog-warning',
    templateUrl: './dialog-warning.component.html',
    styleUrls: ['./dialog-warning.component.scss'],
})
export class DialogWarningComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<DialogWarningComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    typeWarning:
        | 'WARNING'
        | 'CONFIRM'
        | 'CONFIRM_DELETE'
        | 'REDIRECT_LOGIN'
        | 'NOTI_SHORT_DAY'
        | 'CHANGE_STATUS_USER' = this.data.typeWarning;
    dataWarning = this.data.dataWarning;

    onOk(): void {
        switch (this.typeWarning) {
            case 'CONFIRM_DELETE':
            case 'CHANGE_STATUS_USER':
                this.dialogRef.close(this.typeWarning);
                break;
            case 'REDIRECT_LOGIN':
                this.dialogRef.close('REDIRECT_LOGIN');
                break;
            default:
                this.dialogRef.close(1);
                break;
        }
    }
    onClose() {
        switch (this.typeWarning) {
            case 'NOTI_SHORT_DAY':
                this.dialogRef.close('NOTI_SHORT_DAY');
                break;
            default:
                this.dialogRef.close();
                break;
        }
    }

    ngOnInit(): void {
        $('.cdk-overlay-container').css('z-index', '3');
    }
}
