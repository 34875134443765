import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomSnackBarService } from '../../utils/custom-snack-bar.service';
import { UserService } from '../../services/user.service';
declare var $: any;

@Component({
  selector: 'app-dialog-forget-password',
  templateUrl: './dialog-forget-password.component.html',
  styleUrls: ['./dialog-forget-password.component.scss']
})
export class DialogForgetPasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup;

  constructor(
    private _snackBar: CustomSnackBarService,
    private userService: UserService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogForgetPasswordComponent>
  ) {}

  ngOnInit(): void {
    $('.cdk-overlay-container').css('z-index', '3');

    this.initForm();
  }

  initForm() {
    this.forgetPasswordForm = this.fb.group({
      email: new FormControl(null)
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (!this.forgetPasswordForm.valid) {
      this.forgetPasswordForm.markAllAsTouched();
      return;
    }
    const data = this.forgetPasswordForm.value;

    const req = {
      email: data.email
    };
    this.userService.requestResetPassword(req).subscribe(
      res => {
        this.dialogRef.close(res);
      },
      _ => {
        if (environment.closeDialogAfterRequestFail) {
          this.dialogRef.close();
        }
      }
    );
  }
}
