
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class UserGroupService {

    userGroupApi = `${this.apiConstants.serviceEndpoint}/UserGroup`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getUserGroup(id: string) : Observable<any>{
        return this.http.get(`${this.userGroupApi}/get-user-group/${id}`);
    }

    getUserGroups(body: any) : Observable<any>{
        return this.http.post(`${this.userGroupApi}/get-user-groups`, body);
    }

    createUserGroup(body: any) {
        return this.http.post(`${this.userGroupApi}/create-user-group`, body);
    }

    updateUserGroup(body: any) {
        return this.http.put(`${this.userGroupApi}/update-user-group/${body.id}`, body);
    }

    deleteUserGroup(id: string): Promise<any> {
        return this.http.delete(`${this.userGroupApi}/delete-user-group/${id}`).toPromise();
    }

    deleteUserGroups(body: string[]): Promise<any> {
        return this.http.post(`${this.userGroupApi}/delete-user-groups`, body).toPromise();
    }
}
