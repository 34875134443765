<div class="preview-document">
  <iframe
    *ngIf="viewType === 'office' || viewType === 'pdf'"
    [src]="fileUrl"
    width="100%"
    height="700px"
    frameborder="0"
  >
    This is an embedded
    <a target="_blank" href="http://office.com">Microsoft Office</a> document,
    powered by
    <a target="_blank" href="http://office.com/webapps">Office Online</a>.
  </iframe>

  <img *ngIf="viewType === 'image'" class="preview-img" [src]="fileUrl" width="100%" />
</div>
