
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class ReviewService {

    reviewApi = `${this.apiConstants.serviceEndpoint}/Review`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getReview(id: string) : Observable<any>{
        return this.http.get(`${this.reviewApi}/get-review/${id}`);
    }

    getReviews(body: any) : Observable<any>{
        return this.http.post(`${this.reviewApi}/get-reviews`, body);
    }

    createReview(body: any) {
        return this.http.post(`${this.reviewApi}/create-review`, body);
    }

    updateReview(body: any) {
        return this.http.put(`${this.reviewApi}/update-review/${body.id}`, body);
    }

    deleteReview(id: string): Promise<any> {
        return this.http.delete(`${this.reviewApi}/delete-review/${id}`).toPromise();
    }

    deleteReviews(body: string[]): Promise<any> {
        return this.http.post(`${this.reviewApi}/delete-reviews`, body).toPromise();
    }

    getReviewByInititativeId(inititativeId: string): Observable<any> {
        return this.http.get(`${this.reviewApi}/get-review-by-initiative-id/${inititativeId}`);
    }

    giveResult(body: any): Observable<any> {
        return this.http.post(`${this.reviewApi}/give-result`, body);
    }

    finishReview(body: any): Observable<any> {
        return this.http.post(`${this.reviewApi}/finish-review`, body);
    }
}
