import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { API_TOKEN, ApiConstant } from 'src/app/modules';

@Component({
  selector: 'preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class DialogPreviewDocumentComponent implements OnInit {
  viewType?: 'office' | 'image' | 'pdf';

  fileUrl;

  constructor(
    public dialogRef: MatDialogRef<DialogPreviewDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(API_TOKEN) private apiConstants: ApiConstant,
    private sanitizer: DomSanitizer
  ) {
    this.classifyThenCreatePreviewUrl();
  }

  ngOnInit() {}

  classifyThenCreatePreviewUrl() {
    const fileType = this.data?.fileItem?.fileType;
    this.viewType = undefined;
    const fileUrl = `${this.apiConstants.serviceEndpoint}/InitiativeDocument/download/${this.data?.fileItem.id}`;
    let previewUrl = '';

    if (['excel', 'word', 'ppt'].includes(fileType)) {
      this.viewType = 'office';
      previewUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`;
    } else if (['image'].includes(fileType)) {
      this.viewType = 'image';
      previewUrl = fileUrl;
    } else if (fileType === 'pdf') {
      previewUrl = fileUrl;
      this.viewType = 'pdf';
    }
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(previewUrl);
  }
}
