
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class ProvinceService {

    provinceApi = `${this.apiConstants.serviceEndpoint}/Province`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getProvince(id: string) : Observable<any>{
        return this.http.get(`${this.provinceApi}/get-province/${id}`);
    }

    getProvinces(body: any) : Observable<any>{
        return this.http.post(`${this.provinceApi}/get-provinces`, body);
    }

    createProvince(body: any) {
        return this.http.post(`${this.provinceApi}/create-province`, body);
    }

    updateProvince(body: any) {
        return this.http.put(`${this.provinceApi}/update-province/${body.id}`, body);
    }

    deleteProvince(id: string): Promise<any> {
        return this.http.delete(`${this.provinceApi}/delete-province/${id}`).toPromise();
    }

    deleteProvinces(body: string[]): Promise<any> {
        return this.http.post(`${this.provinceApi}/delete-provinces`, body).toPromise();
    }
}
