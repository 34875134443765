
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class MenuService {

    menuApi = `${this.apiConstants.serviceEndpoint}/SystemFunction`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getMenu(id: string) : Observable<any>{
        return this.http.get(`${this.menuApi}/get-menu/${id}`);
    }

    getMenus(body: any) : Observable<any>{
        return this.http.post(`${this.menuApi}/get-menus`, body);
    }

    createMenu(body: any) {
        return this.http.post(`${this.menuApi}/create-system-function`, body);
    }

    updateMenu(body: any) {
        return this.http.put(`${this.menuApi}/update-system-function/${body.id}`, body);
    }

    deleteMenu(id: string): Promise<any> {
        return this.http.delete(`${this.menuApi}/delete-system-function/${id}`).toPromise();
    }

    deleteMenus(body: string[]): Promise<any> {
        return this.http.post(`${this.menuApi}/delete-system-functions`, body).toPromise();
    }
}
