import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { get } from 'lodash';

export interface ColumnTable {
  display: string;
  dataIndex: string;
  width: string | number;
  isSpecial?: boolean;
  customDataIndex?: string;
}
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterViewInit {
  get = get;
  @Input() set columns (value: ColumnTable[]) {
    if (value) {
      this.columnsData = value;
      this.displayColumns = value.map(x => x.dataIndex);
    } else {
      this.displayColumns = [];
      this.columnsData = [];
    }
  }
  @Input() set data (value: any[]) {
    this.dataSource = value;
  };

  @Input() tableName: string;

  columnsData: ColumnTable[] = [];
  displayColumns: string[] = [];
  dataSource: any;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {}

  convertFixedValue(isPass: string): string {
    return isPass != '' && isPass.includes('.') && isPass.length > 5
      ? parseFloat(parseFloat(isPass).toFixed(2)).toString()
      : isPass;
  }
}
