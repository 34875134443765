import * as jwt_decode from "jwt-decode";
import { TokenPayload } from "../types";

export function getRole() {
  var res: string[];
  const jwt = localStorage.getItem('jwt');
  if (!jwt) {
    return null;
  }
  const token: TokenPayload = jwt_decode<TokenPayload>(jwt);
  Object.keys(token).map(function(key) {
    const arrSpl = key.split('/');
    if (arrSpl && arrSpl.length > 0 && arrSpl[arrSpl.length - 1] === 'role') {
      res = token[key];
    }
  });
  return res;
}

export function getJWT() {
  try {
    const jwt = localStorage.getItem('jwt');
    if (!jwt) {
      return null;
    }
    const token: TokenPayload = jwt_decode<TokenPayload>(jwt);
    const isExpired = Date.now() > token.exp * 1000;
    if (isExpired) {
      localStorage.removeItem('jwt');
      return null;
    }
    return jwt;
  } catch {
    localStorage.removeItem('jwt');
    return null;
  }
}

export function isAuthorized(code: any, objCode?: any, id?: any) {
  var access: boolean = false;
  const jwt = getJWT();
  if (!jwt) {
    return false;
  }
  const roles = getRole();

  if (roles && roles.length > 0) {
    access = roles.includes(code);
  }
  return access;
}
