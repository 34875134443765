<div class="main-register">
    <div class="header-main-register">
        <div class="logo" (click)="goToHome()">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200.5" height="27" viewBox="0 255 747 70" style="enable-background:new 0 255 747 78;" xml:space="preserve">
        <path d="M89.4,269c0-2.4-0.8-4.3-2.4-5.7c-1.6-1.4-3.5-2-5.7-2H16.4v52.5H5.9v-63h74.6c5.9,0,10.6,1.4,14.1,4.3
          c3.5,2.8,5.3,6.7,5.3,11.5v47.2H89.4V269z M47.6,269.9h10.5v43.9H47.6V269.9z"/>
        <path d="M140.5,313.8c-5.9,0-10.6-1.4-14.1-4.3c-3.5-2.8-5.3-6.7-5.3-11.5v-31.4c0-4.9,1.8-8.7,5.3-11.5c3.5-2.8,8.2-4.3,14.1-4.3
          h34.9c5.9,0,10.6,1.4,14.1,4.3c3.5,2.8,5.3,6.7,5.3,11.5V298c0,4.9-1.8,8.7-5.3,11.5c-3.5,2.8-8.2,4.3-14.1,4.3H140.5z M184.3,269
          c0-2.4-0.8-4.3-2.3-5.7c-1.5-1.4-3.4-2-5.5-2h-37c-2.1,0-4,0.7-5.5,2c-1.5,1.4-2.3,3.2-2.3,5.7v26.6c0,2.1,0.8,3.9,2.3,5.4
          c1.5,1.5,3.4,2.3,5.5,2.3h37c2.1,0,4-0.7,5.5-2c1.5-1.4,2.3-3.2,2.3-5.7V269z"/>
        <path class="st4" d="M-402.2,385.4c0-2.5-0.8-4.4-2.5-5.8c-1.7-1.4-3.6-2.1-5.8-2.1h-66.7v54H-488v-64.8h76.6
          c6.1,0,10.9,1.5,14.5,4.4c3.6,2.9,5.4,6.9,5.4,11.9v48.5h-10.7V385.4z M-445.1,386.3h10.8v45.1h-10.8V386.3z"/>
        <path d="M216.1,261.3v-10.5h55.4c3.1,0,5.7,0.4,8,1.2c2.3,0.8,4.2,2,5.7,3.4c1.5,1.4,2.6,3.1,3.4,5c0.8,1.9,1.1,4,1.1,6.1v5.3
          c0,2-0.4,4-1.1,5.8c-0.8,1.9-1.9,3.5-3.4,5c-1.5,1.5-3.4,2.6-5.7,3.5c-2.3,0.9-5,1.4-8,1.4h-33.2l51.4,26.2h-22.3l-51.4-26.1v-10.5
          h55.7c2.3,0,4.1-0.7,5.4-2.1c1.3-1.4,2-3.4,2-6c0-2.6-0.7-4.6-2-5.9c-1.3-1.3-3.1-1.9-5.4-1.9H216.1z"/>
        <path d="M311.3,261.3v-10.5h73.5v10.5H311.3z M321.8,287.6v26.2h-10.5v-36.7h73.5v10.5H321.8z"/>
        <path d="M469.2,250.8h10.5V298c0,4.9-1.8,8.7-5.3,11.5c-3.5,2.8-8.2,4.3-14.1,4.3h-34.9c-5.9,0-10.6-1.4-14.1-4.3
          c-3.5-2.8-5.3-6.7-5.3-11.5v-47.2h10.5v44.8c0,2.1,0.8,3.9,2.3,5.4c1.5,1.5,3.4,2.3,5.5,2.3h37c2.1,0,4-0.7,5.5-2
          c1.5-1.4,2.3-3.2,2.3-5.7V250.8z"/>
        <path d="M518.6,261.3c-4.6,0-7,2.6-7,7.9c0,5.3,2.3,7.9,7,7.9h0.9v10.4c-12.3,0-18.5-5-18.5-15.1v-6.1c0-10.3,6.1-15.5,18.3-15.5
          h55.2v10.5H518.6z M557,303.3c4.6,0,7-2.6,7-7.9c0-5.3-2.3-7.9-7-7.9h-0.9v-10.4c6.1,0,10.7,1.2,13.8,3.6c3.1,2.4,4.7,6.2,4.7,11.4
          v6.1c0,5.2-1.5,9.1-4.4,11.6c-2.9,2.6-7.5,3.8-13.8,3.8h-55.3v-10.5H557z"/>
        <path d="M606.4,313.8h-10.5v-10.5h10.5V313.8z"/>
        <path d="M711.4,313.8h-86.6l32.3-52.5c2.2-3.7,4.2-6.4,5.8-8.1c1.7-1.6,3-2.4,4-2.4h2.3c0.4,0,0.9,0.1,1.5,0.4
          c0.6,0.3,1.2,0.8,2,1.6c0.8,0.8,1.7,1.8,2.7,3.2c1,1.4,2.3,3.2,3.6,5.3L711.4,313.8z M668.1,264.1h-0.2l-24.4,39.2h49L668.1,264.1z"
          />
        <path d="M740.3,250.8v63h-10.6v-63H740.3z"/>
        </svg>
        </div>
        <div class="login-btn">
            <button (click)="goToLogin()" class="btn login-button">Login</button>
        </div>
    </div>
    <div class="box-register" *ngIf="!registerSuccess && !codeActive">
        <div class="header-title-register">Account Creation</div>
        <div [formGroup]="registerForm" class="form-register">
            <div class="item-form-register">
                <div class="label-item-form">User Name</div>
                <div class="input-item-form">
                    <input formControlName="username" type="text" class="form-control" name="username" placeholder="Username" (keydown.space)="$event.preventDefault()" />
                </div>
                <mat-error *ngIf="validateForm('username', 'required')">
                    The username field is required
                </mat-error>
            </div>
            <div class="item-form-register">
                <div class="label-item-form">Email Address</div>
                <div class="input-item-form">
                    <input formControlName="email" type="text" class="form-control" name="email" placeholder="Email Address" (blur)="trimValue('email')" />
                </div>
                <mat-error *ngIf="validateForm('email', 'required') && !validateForm('email','email')">
                    The email field is required
                </mat-error>
                <mat-error *ngIf="email.errors && !email.errors['required'] && registerForm.invalid">
                    This is not a valid email
                </mat-error>
            </div>
            <div class="item-form-register">
                <div class="label-item-form">Password</div>
                <div class="input-item-form">
                    <input formControlName="password" [type]="isShowPassword ? 'text' : 'password'" class="form-control pd-r-36" name="password" placeholder="Password" (blur)="trimValue('password')" />
                    <div class="show-hide-password">
                        <i (click)="this.isShowPassword = !this.isShowPassword" class="{{ isShowPassword ? 'icon-eye-off' : 'icon-eye' }}"></i>
                    </div>
                </div>
                <mat-error *ngIf="validateForm('password', 'required') && !validateForm('password','password')">
                    The password field is required
                </mat-error>
                <mat-error *ngIf="password.errors && !password.errors['required'] && registerForm.invalid">
                    This is not a valid password
                </mat-error>
                <div class="description-item-form pd-t-17">Minimum 8 characters long</div>
                <div class="description-item-form">At least one capital letter and one number</div>
                <div class="description-item-form">{{ passwordLastDes }}</div>
            </div>
            <div class="item-form-register">
                <div class="label-item-form">Repeat Password</div>
                <div class="input-item-form">
                    <input formControlName="rePassword" [type]="isShowRePassword ? 'text' : 'password'" class="form-control pd-r-36" name="rePassword" placeholder="Repeat Password" (blur)="trimValue('rePassword')" />
                    <div class="show-hide-password">
                        <i (click)="this.isShowRePassword = !this.isShowRePassword" class="{{ isShowRePassword ? 'icon-eye-off' : 'icon-eye' }}"></i>
                    </div>
                </div>
                <mat-error *ngIf="validateForm('rePassword', 'required') && !validateForm('rePassword','rePassword')">
                    The repeat password field is required
                </mat-error>
                <mat-error *ngIf="validateForm('rePassword','rePassword') && !validateForm('rePassword', 'required')">
                    The repeat password incorrect
                </mat-error>
            </div>
        </div>
        <div class="form-check pl-0 cl-red">
            {{ registerError$ | async }}
        </div>
        <div class="bottom-register-form">
            <button (click)="register()" class="btn register-button">Get Verification</button>
        </div>
    </div>
    <div class="box-register" *ngIf="registerSuccess && !codeActive">
        <div class="text-comfirm-success">Registered store account successfully. Please check your Email to activate your account.</div>
    </div>
    <div class="box-register" *ngIf="codeActive && activeSuccess && showResultActive">
        <div class="text-comfirm-success">The account store has been activated, Click here to <a (click)="goToLogin()" class="cursor-pt hover-color">Login </a>.</div>
    </div>
    <div class="box-register" *ngIf="codeActive && !activeSuccess && showResultActive">
        <div class="text-comfirm-success">Account activation failed. Please contact Admin.</div>
        <div class="form-check pl-0 cl-red">
            {{ activeError$ | async }}
        </div>
    </div>
</div>
<div *ngIf="isLoading && ((registerError$ | async) ==  '' || !(registerError$ | async)) && ((activeError$ | async) == '' || !(activeError$ | async))">
    <app-loading></app-loading>
</div>