
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class InitiativeDocumentService {

    initiativeDocumentApi = `${this.apiConstants.serviceEndpoint}/InitiativeDocument`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getInitiativeDocument(id: string): Observable<any> {
        return this.http.get(`${this.initiativeDocumentApi}/get-initiative-document/${id}`);
    }

    getByInitiative(initiativeId: string): Observable<any> {
        return this.http.get(`${this.initiativeDocumentApi}/get-by-initiative/${initiativeId}`);
    }

    getInitiativeDocuments(body: any): Observable<any> {
        return this.http.post(`${this.initiativeDocumentApi}/get-initiative-documents`, body);
    }

    createInitiativeDocument(body: any, headers: any) {
        return this.http.post(`${this.initiativeDocumentApi}/create-initiative-document`, body, { headers: headers });
    }

    updateInitiativeDocument(body: any) {
        return this.http.put(`${this.initiativeDocumentApi}/update-initiative-document/${body.id}`, body);
    }

    deleteInitiativeDocument(id: string): Promise<any> {
        return this.http.delete(`${this.initiativeDocumentApi}/delete-initiative-document-hard/${id}`).toPromise();
    }

    deleteInitiativeDocuments(body: string[]): Promise<any> {
        return this.http.post(`${this.initiativeDocumentApi}/delete-initiative-documents`, body).toPromise();
    }

    downloadInitiativeDocument(id: string): Observable<any> {
        return this.http.get(`${this.initiativeDocumentApi}/download/${id}`, {
            responseType: 'blob' as 'json'
        });
    }

    getByInitiativePost(initiativeId: string): Observable<any> {
      return this.http.post(`${this.initiativeDocumentApi}/get-by-initiative`, {initiativeId});
  }
}
