
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class CouncilMemberService {

    councilMemberApi = `${this.apiConstants.serviceEndpoint}/CouncilMember`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getCouncilMember(id: string) : Observable<any>{
        return this.http.get(`${this.councilMemberApi}/get-council-member/${id}`);
    }

    getCouncilMembers(body: any) : Observable<any>{
        return this.http.post(`${this.councilMemberApi}/get-council-members`, body);
    }

    createCouncilMember(body: any) {
        return this.http.post(`${this.councilMemberApi}/create-council-member`, body);
    }

    updateCouncilMember(body: any) {
        return this.http.put(`${this.councilMemberApi}/update-council-member/${body.id}`, body);
    }

    deleteCouncilMember(id: string): Promise<any> {
        return this.http.delete(`${this.councilMemberApi}/delete-council-member/${id}`).toPromise();
    }

    deleteCouncilMembers(body: string[]): Promise<any> {
        return this.http.post(`${this.councilMemberApi}/delete-council-members`, body).toPromise();
    }
}
