export const mapRoute = [
  {
    functionCode: 'DangKySKKNXetDuyet',
    fixedPath: '/administrator/general/register-initiative',
    params: { isExceptional: false },
  },
  {
    functionCode: 'DangKySKKNDacCach',
    fixedPath: '/administrator/general/register-initiative',
    params: { isExceptional: true },
  },
  {
    functionCode: 'XetDuyet',
    fixedPath: '/administrator/general/review',
    params: {},
  },
  {
    functionCode: 'QuanLyTieuChi',
    fixedPath: '/administrator/general/criteria',
    params: {},
  },
  {
    functionCode: 'QuanLyHoiDong',
    fixedPath: '/administrator/general/council',
    params: {},
  },
  {
    functionCode: 'QuanLyDotXetDuyet',
    fixedPath: '/administrator/general/review-period',
    params: {},
  },
  {
    functionCode: 'QuanLyThanhVienHoiDong',
    fixedPath: '/administrator/general/council-member',
    params: {},
  },
  {
    functionCode: 'QuanLySkkn',
    fixedPath: '/administrator/general/initiative',
    params: { },
  },
  {
    functionCode: 'QuanLySkknXetDuyet',
    fixedPath: '/administrator/general/initiative',
    params: { isExceptional: false },
  },
  {
    functionCode: 'QuanLySkknDacCach',
    fixedPath: '/administrator/general/initiative',
    params: { isExceptional: true },
  },
  {
    functionCode: 'QuanLyChucVu',
    fixedPath: '/administrator/general/position',
    params: {},
  },
  {
    functionCode: 'BaoCao',
    fixedPath: '/administrator/general/report',
    params: {},
  },
  {
    functionCode: 'QuanLyLoaiDonVi',
    fixedPath: '/administrator/general/major',
    params: {},
  },
  {
    functionCode: 'QuanLyDonVi',
    fixedPath: '/administrator/general/organization',
    params: {},
  },
  {
    functionCode: 'QuanLyNamLamViec',
    fixedPath: '/administrator/general/working-year',
    params: {},
  },
  {
    functionCode: 'QuanLyNguoiDung',
    fixedPath: '/administrator/general/user',
    params: {},
  },
  {
    functionCode: 'QuanLyNhomNguoiDung',
    fixedPath: '/administrator/general/group',
    params: {},
  },
  {
    functionCode: 'QuanLyVanBan',
    fixedPath: '/administrator/general/office-document',
    params: {},
  },
  {
    functionCode: 'QuanLyLoaiVanBan',
    fixedPath: '/administrator/general/office-document-type',
    params: {},
  },
  {
    functionCode: 'QuanLyLinhVuc',
    fixedPath: '/administrator/general/initiative-field',
    params: {},
  },
  {
    functionCode: 'BaoCaoTongHopKetQuaChamDiem',
    fixedPath: '/administrator/general/total-review-by-period',
    params: {},
  },
  {
    functionCode: 'QuyetDinhCongNhanSkkn',
    fixedPath: '/administrator/general/recognition-decisions',
    params: {},
  },
  {
    functionCode: 'BaoCaoTongHopSoLuong',
    fixedPath: '/administrator/general/total-initiative-by-period',
    params: {},
  },
  {
    functionCode: 'Dashboard',
    fixedPath: '/administrator/dashboard',
    params: {},
  },
  {
    functionCode: 'PhieuDanhGia',
    fixedPath: '/administrator/general/evaluation-form',
    params: {},
  },
  {
    functionCode: 'PhieuNhanXet',
    fixedPath: '/administrator/general/assessment-form',
    params: {},
  },
  {
    functionCode: 'PhuLucBienBanHopHoiDongChuyenMon',
    fixedPath: '/administrator/general/professional-council-meeting',
    params: {},
  },
  {
    functionCode: 'DanhMucXaPhuong',
    fixedPath: '/administrator/general/village',
    params: {},
  },
  {
    functionCode: 'DanhMucHuyen',
    fixedPath: '/administrator/general/district',
    params: {},
  },
  {
    functionCode: 'DanhMucTinhThanhPho',
    fixedPath: '/administrator/general/province',
    params: {},
  },
  {
    functionCode: 'QuanLyThamSo',
    fixedPath: '/administrator/general/app-setting',
    params: {},
  },
  {
    functionCode: 'QuanLyLoaiThongBao',
    fixedPath: '/administrator/general/announcement-type',
    params: {},
  },
  {
    functionCode: 'QuanLyThongBao',
    fixedPath: '/administrator/general/announcement',
    params: {}
  },
  {
    functionCode: 'QuanLyMenu',
    fixedPath: '/administrator/general/menu',
    params: {},
  },
  {
    functionCode: 'DoiChieuThiDua',
    fixedPath: '/administrator/general/comparing-competition',
    params: {},
  }
]
