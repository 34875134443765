
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class GroupService {

    groupApi = `${this.apiConstants.serviceEndpoint}/Group`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getGroup(id: string) : Observable<any>{
        return this.http.get(`${this.groupApi}/get-group/${id}`);
    }

    getGroups(body: any) : Observable<any>{
        return this.http.post(`${this.groupApi}/get-groups`, body);
    }

    createGroup(body: any) {
        return this.http.post(`${this.groupApi}/create-group`, body);
    }

    updateGroup(body: any) {
        return this.http.put(`${this.groupApi}/update-group/${body.id}`, body);
    }

    deleteGroup(id: string): Promise<any> {
        return this.http.delete(`${this.groupApi}/delete-group/${id}`).toPromise();
    }

    deleteGroups(body: string[]): Promise<any> {
        return this.http.post(`${this.groupApi}/delete-groups`, body).toPromise();
    }
}
