import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-release-notes',
  templateUrl: './dialog-release-notes.component.html',
  styleUrls: ['./dialog-release-notes.component.scss']
})
export class DialogReleaseNotesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogReleaseNotesComponent>
  ) { }

  ngOnInit(): void {
  }

}
