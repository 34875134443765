<div class="main-modal row">
  <div class="logo-area col-12">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="main-logo">
        <img src="assets/images/main-logo.png" />
      </div>
      <div>
        <div class="name">
          PHẦN MỀM QUẢN LÝ SÁNG KIẾN KINH NGHIỆM THÀNH PHỐ HÀ NỘI
        </div>
        <div class="slogan">SỞ KHOA HỌC VÀ CÔNG NGHỆ</div>
      </div>
    </div>
  </div>
  <div class="login-form-area">
    <div class="form">
      <div>
        <h3 class="mid-title">Đăng nhập vào hệ thống</h3>
        <div [formGroup]="loginForm">
          <div class="form-shadow mx-4 mx-xl-0">
            <div class="form-group-custom">
              <div class="input-group">
                <!-- <div class="input-group-prepend">
                <div class="input-group-icon"><i class="icon-mail"></i></div>
              </div> -->
                <div class="input-group-control flex-grow-1">
                  <!-- <input formControlName="email" type="text" class="form-control" name="email"
                                    placeholder="Your Email address" (blur)="trimValue('email')" /> -->
                  <input
                    formControlName="username"
                    type="text"
                    class="form-control"
                    name="username"
                    placeholder="Username"
                    (blur)="trimValue('username')"
                  />
                </div>
              </div>
              <mat-error *ngIf="validateForm('username', 'required')">
                Vui lòng điền tên đăng nhập.
              </mat-error>
              <!-- <mat-error *ngIf="validateForm('email','email')">
                            This is not a valid email
                        </mat-error> -->
            </div>
            <div class="form-group-custom form-group-password mb-0">
              <div class="input-group">
                <!-- <div class="input-group-prepend">
                <div class="input-group-icon"><i class="icon-key"></i></div>
              </div> -->
                <div class="input-group-control flex-grow-1">
                  <input
                    formControlName="password"
                    [type]="isShowPassword ? 'text' : 'password'"
                    class="form-control"
                    name="password"
                    placeholder="Password"
                    (keyup.enter)="login()"
                  />
                </div>
                <!-- <div class="input-group-append">
                <div class="input-group-icon toggle-password">
                  <i (click)="this.isShowPassword = !this.isShowPassword"
                    class="{{ isShowPassword ? 'icon-eye-off' : 'icon-eye' }}"></i>
                </div>
              </div> -->
              </div>
              <mat-error *ngIf="validateForm('password', 'required')">
                Vui lòng điền mật khẩu.
              </mat-error>
            </div>
          </div>
          <div
            class="form-action d-flex justify-content-between align-items-center mt-lg-2 mt-3 mb-3"
          >
            <div class="form-check pl-0 cl-red px-4">
              {{ loginError$ | async }}
            </div>
            <div class="form-link align-items-center forgot-password-right">
              <a (click)="forgetPassword()" class="cursor-pt hover-color">
                Quên mật khẩu</a
              >
            </div>
          </div>
          <div
            class="form-button d-flex flex-column flex-lg-row flex-column-reverse justify-content-between align-items-center"
          >
            <button (click)="login()" class="mid-button">Đăng nhập</button>
          </div>
          <!-- <div class="form-link mt-2 align-items-center">
                        If you haven't a account.
                    </div> -->


        </div>
      </div>
    </div>
  </div>
</div>

<div class="release-notes">
  <!-- <span (click)="openDialogReleaseNotes()">Release notes</span> -->
</div>

<app-loading *ngIf="isLoading"></app-loading>
