import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from './auth.service';
import { isNil } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(protected authService: AuthService, protected router: Router) { }

    canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url = state.url;
        const validLogin = this.checkLogin(url);
        if (!validLogin) {
            return of(true);
        }
        const validOnboard = this.checkOnboard(url);
        if (!validOnboard) {
            return of(true);
        }
        // const user = this.authService.getUser();
        // if (isNil(user.emailVerified)) {
        //   this.authService.logout();
        //   return of(true);
        // }
        return validLogin;
    }


    private checkLogin(url: string) {
        if (this.authService.isLogin()) {
            if (this.authService.isOnboard()) {
                if (url !== '/onboard') {
                    this.router.navigate(['/onboard']);
                }
                return true;
            }
            if (url === '/auth/login') {
                this.router.navigate(['/']);
            }
            return true;
        }

        if (url !== '/auth/login') {
            // Store the attempted URL for redirecting
            this.authService.redirectUrl = url;
            this.router.navigate(['/auth/login']);
            return false;
        }
        return true;
    }

    private checkOnboard(url: string) {
        if (this.authService.isOnboard()) {
            if (url !== '/onboard') {
                this.router.navigate(['/onboard']);
            }
            return true;
        }
        return true;
    }
}
