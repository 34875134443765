import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class FunctionService {

    functionApi = `${this.apiConstants.serviceEndpoint}/Function`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getAllFunctions(groupId: string) : Observable<any>{
      if (groupId!="")
          return this.http.get(`${this.functionApi}/get-all-functions?groupId=${groupId}`);
        else
          return this.http.get(`${this.functionApi}/get-all-functions`);
    }
}
