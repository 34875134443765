import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralSharedService } from 'src/app/modules/shared/services';
import { mapRoute } from '../../constants/map-route';
import { MapRouteService } from '../../services';

@Component({
  selector: 'app-custom-view-path',
  template: '',
  styleUrls: []
})
export class CustomViewPathComponent implements OnInit {
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private mapRouteService: MapRouteService

  ) { }

  async ngOnInit() {
    const menus = await this.mapRouteService.getMenus();
    const menu = menus.find(s => s.fullViewPath.toLowerCase() === location.pathname.toLowerCase());
    if (!menu) {
      this.router.navigate(['/'], { replaceUrl: true });
      return;
    }
    const fixedRoute = mapRoute.find(s => s.functionCode === menu.functionCode);
    if (!fixedRoute) {
      this.router.navigate(['/'], { replaceUrl: true });
      return;
    }
    this.router.navigate([fixedRoute.fixedPath], {
      skipLocationChange: true,
      queryParams: {...this.activeRoute.snapshot.queryParams, ...fixedRoute.params}
    });
  }
}
