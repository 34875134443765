
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class ReviewPeriodService {

    reviewPeriodApi = `${this.apiConstants.serviceEndpoint}/ReviewPeriod`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getReviewPeriod(id: string): Observable<any> {
        return this.http.get(`${this.reviewPeriodApi}/get-review-period/${id}`);
    }

    getReviewPeriods(body: any): Observable<any> {
        return this.http.post(`${this.reviewPeriodApi}/get-review-periods`, body);
    }

    createReviewPeriod(body: any) {
        return this.http.post(`${this.reviewPeriodApi}/create-review-period`, body);
    }

    updateReviewPeriod(body: any) {
        return this.http.put(`${this.reviewPeriodApi}/update-review-period/${body.id}`, body);
    }

    deleteReviewPeriod(id: string): Promise<any> {
        return this.http.delete(`${this.reviewPeriodApi}/delete-review-period/${id}`).toPromise();
    }

    deleteReviewPeriods(body: string[]): Promise<any> {
        return this.http.post(`${this.reviewPeriodApi}/delete-review-periods`, body).toPromise();
    }

    getReviewPeriodByInititativeId(inititativeId: string): Observable<any> {
        return this.http.get(`${this.reviewPeriodApi}/get-review-period-by-initiative-id/${inititativeId}`);
    }
}
