import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
@Injectable({
  providedIn: "root",
})
export class CommonFunction {
  constructor(private matSnackBar: MatSnackBar) {}

  getClassIcon1(name: string) {
    if (name) {
      switch (name.toLowerCase()) {
        case "lounges":
          return "icon-couch";
        case "cafes":
          return "icon-coffee";
        case "airplanes":
          return "icon-plane";
        case "malls":
          return "icon-malls";
        case "villages":
          return "icon-vIllages";
        case "buses":
          return "icon-bus";
        case "cars":
          return "icon-car";
        case "expo":
          return "assets/images/expo-icon.svg";
        default:
          return "icon-" + name.toLowerCase();
      }
    }
  }

  openSnackBar(message: string, status: string = "SUCCESS") {
    let className =
      status == "SUCCESS"
        ? "messenger-success"
        : status == "WANRING"
        ? "messenger-waning"
        : "messenger-fail";

    this.matSnackBar.open(message, "", {
      horizontalPosition: "end",
      verticalPosition: "top",
      duration: 3000,
      panelClass: className,
    });
  }

  playAudio() {
      let audio = new Audio();
      audio.src = "../../../../assets/audio/noti_sound.mp3";
      audio.load();
      audio.play();
  }
}
