
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class PositionService {

    positionApi = `${this.apiConstants.serviceEndpoint}/Position`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getPosition(id: string) : Observable<any>{
        return this.http.get(`${this.positionApi}/get-position/${id}`);
    }

    getPositions(body: any) : Observable<any>{
        return this.http.post(`${this.positionApi}/get-positions`, body);
    }

    createPosition(body: any) {
        return this.http.post(`${this.positionApi}/create-position`, body);
    }

    updatePosition(body: any) {
        return this.http.put(`${this.positionApi}/update-position/${body.id}`, body);
    }

    deletePosition(id: string): Promise<any> {
        return this.http.delete(`${this.positionApi}/delete-position/${id}`).toPromise();
    }

    deletePositions(body: string[]): Promise<any> {
        return this.http.post(`${this.positionApi}/delete-positions`, body).toPromise();
    }
}
