import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnInit {
  dialog: DialogData = {
    title: "",
    content: "",
    url: "",
    icon: "", // icon : confirm | warning | error
    cancel: false,
    ok: false,
    remove: false,
    type: "",
  };

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialog = {
      title: this.data?.title,
      content: this.data?.content,
      url:this.data?.url,
      icon: this.data?.icon,
      cancel: this.data?.cancel,
      ok: this.data?.ok,
      remove: this.data?.remove,
      type: this.data?.type,
    };
  }

  ngOnInit(): void {}

  onOk() {
    this.dialogRef.close({ click: "ok", type: this.dialog.type });
  }
  onRemove() {
    this.dialogRef.close({ click: "Remove", type: this.dialog.type });
  }
  onClose() {
    this.dialogRef.close({ click: "close", type: this.dialog.type });
  }
}
export interface DialogData {
  title: string;
  content: string;
  url: string;
  icon: string;
  cancel: boolean;
  ok: boolean;
  remove:boolean;
  image?: string;
  type: string;
}
