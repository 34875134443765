<div class="review-information-table">
  <div class="" *ngIf="listHDTP.length">
    <label><strong>Hội đồng thành phố chấm điểm</strong></label>
    <table mat-table [dataSource]="listHDTP">
      <ng-container matColumnDef="index">
        <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <!-- fullName Column -->
      <ng-container matColumnDef="reviewerName">
        <mat-header-cell *matHeaderCellDef>
          Tên người chấm
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element">
          {{ element["reviewerName"] }}
        </mat-cell>
      </ng-container>

      <!-- fullName Column -->
      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef>
          Ý kiến đánh giá
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element">
          {{ element["comment"] }}
        </mat-cell>
      </ng-container>

      <!-- fullName Column -->
      <ng-container matColumnDef="point">
        <mat-header-cell *matHeaderCellDef>
          Đánh giá
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element">
          {{ element["point"] }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedReviewColumns"
      ></mat-header-row>
      <mat-row
        *matRowDef="let element; columns: displayedReviewColumns"
      ></mat-row>
    </table>
  </div>
  <div [class.mt-5]="listHDTP.length" *ngIf="listHDCM.length">
    <label><strong>Hội đồng chuyên môn chấm điểm</strong></label>
    <table mat-table [dataSource]="listHDCM">
      <ng-container matColumnDef="index">
        <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <!-- fullName Column -->
      <ng-container matColumnDef="reviewerName">
        <mat-header-cell *matHeaderCellDef>
          Tên người chấm
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element">
          {{ element["reviewerName"] }}
        </mat-cell>
      </ng-container>

      <!-- fullName Column -->
      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef>
          Ý kiến đánh giá
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element">
          {{ element["comment"] }}
        </mat-cell>
      </ng-container>

      <!-- fullName Column -->
      <ng-container matColumnDef="point">
        <mat-header-cell *matHeaderCellDef>
          Điểm
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element">
          {{ element["point"] }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedReviewColumns"
      ></mat-header-row>
      <mat-row
        *matRowDef="let element; columns: displayedReviewColumns"
      ></mat-row>
    </table>
  </div>
</div>
