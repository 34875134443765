
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class OfficeDocumentService {

    officeDocumentApi = `${this.apiConstants.serviceEndpoint}/OfficeDocument`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getOfficeDocument(id: string) : Observable<any>{
        return this.http.get(`${this.officeDocumentApi}/get-office-document/${id}`);
    }

    getOfficeDocuments(body: any) : Observable<any>{
        return this.http.post(`${this.officeDocumentApi}/get-office-documents`, body);
    }

    createOfficeDocument(formData: any, headers: any) {
        return this.http.post(`${this.officeDocumentApi}/create-office-document`, formData, {headers: headers});
    }

    updateOfficeDocument(formData: any, headers: any) {
        return this.http.put(`${this.officeDocumentApi}/update-office-document/${formData.get("id")}`, formData, {headers: headers});
    }

    deleteOfficeDocument(id: string): Promise<any> {
        return this.http.delete(`${this.officeDocumentApi}/delete-office-document/${id}`).toPromise();
    }

    deleteOfficeDocuments(body: string[]): Promise<any> {
        return this.http.post(`${this.officeDocumentApi}/delete-office-documents`, body).toPromise();
    }
}
