<div class="attachment">
  <table #tableDocuments mat-table [dataSource]="requirementDocuments">
    <ng-container matColumnDef="index">
      <mat-header-cell *matHeaderCellDef [style.width]="'70px'">
        #
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" [style.width]="'70px'">
        {{ i + 1 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="documentName">
      <mat-header-cell *matHeaderCellDef>
        Tên hồ sơ
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [formGroup]="element"
        [style.padding-right]="'8px'"
      >
        <span>
          {{ element["documentName"] }}
          <span *ngIf="element.required" class="required"> * </span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="files">
      <mat-header-cell *matHeaderCellDef>
        Tập tin đính kèm
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [formGroup]="element">
        <div
          class="upload-box inline-block"
          matTooltip="Dung lượng file tối đa: 80MB Các dạng file: word, pdf, ảnh, pptx, excel"
          *ngIf="!disableUpload"
        >
          <span>Chọn tập tin</span>
          <mat-icon>upload</mat-icon>
          <input
            placeholder=""
            type="file"
            [disabled]="disableUpload || isLoading"
            [multiple]="element.multiple"
            (change)="uploadFile($event, element.type, element.multiple)"
          />
        </div>
        <div class="file-list inline-block space-left">
          <div
            class="file-item"
            *ngFor="let item of fileList[element.type]?.files"
          >
            <div class="file-type">
              <div
                class="inline-block icon-file type-{{ item?.fileType }}"
              ></div>
            </div>
            <div class="file-name">
              {{ item.file.name }}
            </div>
            <div class="action">
              <span class="space-right" *ngIf="item.canPreview">
                <mat-icon
                  matTooltip="Xem"
                  (click)="preview(item)"
                  class="cursor-pt"
                >
                  visibility
                </mat-icon>
              </span>
              <span class="space-right" *ngIf="item.canPreview">
                <mat-icon
                  class="cursor-pt"
                  matTooltip="Tải về"
                  (click)="downloadFile(item)"
                >
                  download
                </mat-icon>
              </span>
              <span class="space-right">
                <mat-icon
                  *ngIf="!disableUpload"
                  class="cursor-pt"
                  matTooltip="Xoá"
                  (click)="confirmRemoveFile(element.type, item.id)"
                >
                  close
                </mat-icon>
              </span>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let element; columns: columns"></mat-row>
  </table>
</div>
