import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared';
import { AuthLoginFormComponent } from './auth-login-form/auth-login-form.component';
import { AuthEmailContainer, AuthLoginContainer } from './containers';
import { LoginFormDialogComponent } from './login-form-dialog/login-form-dialog.component';
import { environment } from 'src/environments/environment';
import { AuthRegisterFormComponent } from './auth-register-form/auth-register-form.component';
import { AuthRegisterContainer } from './containers/auth-register.container';
import { LoadingModule } from '../loading/loading.module';
import { AuthVerifyCodeComponent } from './auth-verify-code/auth-verify-code.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule.forRoot(environment.api),
        ReactiveFormsModule,
        TranslateModule,
        LoadingModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [
        AuthEmailContainer,
        AuthLoginFormComponent,
        AuthLoginContainer,
        LoginFormDialogComponent,
        AuthRegisterFormComponent,
        AuthRegisterContainer,
        AuthVerifyCodeComponent
    ],
    exports: [
        CommonModule,
        AuthEmailContainer,
        AuthLoginFormComponent,
        AuthLoginContainer,
        AuthRegisterFormComponent,
        AuthRegisterContainer,
        SharedModule
    ],
})
export class AuthLazyModule {}
