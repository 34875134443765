<div class="swal2-popup swal2-modal swal2-icon-info swal2-show">
    <div class="swal2-header">
        <div *ngIf="typeWarning === 'WARNING' || typeWarning === 'REDIRECT_LOGIN'" class="swal2-icon swal2-icon-show swal2-warning" style="display: flex;">
            <span class="swal2-x-mark">
                <span class="swal2-x-mark-line-left"></span>
            <span class="swal2-x-mark-line-right"></span>
            </span>
        </div>
        <div *ngIf="typeWarning === 'CONFIRM'" class="swal2-icon swal2-icon-show swal2-confirm" style="display: flex;">
            <span class="swal2-x-mark">
                <span class="swal2-x-mark-line-check"></span>
            </span>
        </div>
        <h2 *ngIf="typeWarning === 'WARNING'" class="swal2-title">
            Lỗi
        </h2>
    </div>
    <div class="swal2-content">
        <div id="swal2-content" class="swal2-html-container">
            {{ dataWarning }}
        </div>
    </div>
    <div class="swal2-actions">
        <button *ngIf="['CONFIRM_DELETE', 'NOTI_SHORT_DAY', 'CHANGE_STATUS_USER'].includes(typeWarning)" (click)="onClose()" type="button" class="swal2-confirm swal2-styled" style="background-color: #ddd;">
            Hủy
        </button>
        <button (click)="onOk()" type="button" class="swal2-warning swal2-styled" [ngStyle]="{
                'min-width.px': ['CONFIRM_DELETE', 'NOTI_SHORT_DAY', 'CHANGE_STATUS_USER'].includes(typeWarning)
                    ? '134'
                    : ''
            }">
            {{ typeWarning === 'REDIRECT_LOGIN' ? 'Đăng nhập' : 'Đồng ý' }}
        </button>
    </div>
</div>