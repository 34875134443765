import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_TOKEN } from '../../auth';
import { ApiConstant } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class InitiativeService {
  initiativeApi = `${this.apiConstants.serviceEndpoint}/Initiative`;

  constructor(
    @Inject(API_TOKEN) private apiConstants: ApiConstant,
    private http: HttpClient
  ) {}

  getInitiative(id: string): Observable<any> {
    return this.http.post(`${this.initiativeApi}/get-initiative/${id}`, {});
  }

  getInitiatives(body: any): Observable<any> {
    return this.http.post(`${this.initiativeApi}/get-initiatives`, body);
  }

  createInitiative(body: any) {
    return this.http.post(`${this.initiativeApi}/create-initiative`, body);
  }

  publishNewInitiative(body: any, headers) {
    return this.http.post(
      `${this.initiativeApi}/publish-new-initiative`,
      body,
      { headers: headers }
    );
  }

  updateInitiative(body: any) {
    return this.http.put(
      `${this.initiativeApi}/update-initiative/${body.id}`,
      body
    );
  }

  deleteInitiative(id: string): Promise<any> {
    return this.http
      .delete(`${this.initiativeApi}/delete-initiative/${id}`)
      .toPromise();
  }

  deleteInitiatives(body: string[]): Promise<any> {
    return this.http
      .post(`${this.initiativeApi}/delete-initiatives`, body)
      .toPromise();
  }

  getInitiativesAsync(body: any): Promise<any> {
    return this.http
      .post(`${this.initiativeApi}/get-initiatives`, body)
      .toPromise();
  }

  assignCouncilAsync(body: any): Observable<any> {
    return this.http.post(`${this.initiativeApi}/assign-council`, body);
  }

  assignReviewDocumentAsync(body: any): Observable<any> {
    return this.http.post(`${this.initiativeApi}/assign-review-document`, body);
  }

  updateStateAsync(body: any[]): Observable<any> {
    return this.http.post(`${this.initiativeApi}/update-state`, body);
  }

  getAssignedInitiatives(): Observable<any> {
    return this.http.get(`${this.initiativeApi}/get-assigned-initiatives`);
  }

  getRelatedIndex(body: any): Observable<any> {
    return this.http.post(`${this.initiativeApi}/get-related-index`, body);
  }

  export(body: any): Observable<any> {
    return this.http.post(`${this.initiativeApi}/export-excel`, body, {
      responseType: 'arraybuffer'
    });
  }
}
