
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class MajorService {

    majorApi = `${this.apiConstants.serviceEndpoint}/Major`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getMajor(id: string) : Observable<any>{
        return this.http.get(`${this.majorApi}/get-major/${id}`);
    }

    getMajors(body: any) : Observable<any>{
        return this.http.post(`${this.majorApi}/get-majors`, body);
    }

    createMajor(body: any) {
        return this.http.post(`${this.majorApi}/create-major`, body);
    }

    updateMajor(body: any) {
        return this.http.put(`${this.majorApi}/update-major/${body.id}`, body);
    }

    deleteMajor(id: string): Promise<any> {
        return this.http.delete(`${this.majorApi}/delete-major/${id}`).toPromise();
    }

    deleteMajors(body: string[]): Promise<any> {
        return this.http.post(`${this.majorApi}/delete-majors`, body).toPromise();
    }
}
