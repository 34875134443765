
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
  providedIn: "root"
})
export class DashboardService {

  dashboardApi = `${this.apiConstants.serviceEndpoint}/Dashboard`;

  constructor(
    @Inject(API_TOKEN) private apiConstants: ApiConstant,
    private http: HttpClient
  ) { }

  getChart() : Observable<any>{
    return this.http.get(`${this.dashboardApi}/get-chart`, {});
  }

  getChartByReviewPeriod(fromDate, toDate) : Observable<any>{
    return this.http.get(`${this.dashboardApi}/get-chart-by-review-period?fromDate=${fromDate}&toDate=${toDate}`, {});
  }

  getChartByOrganization() : Observable<any>{
    return this.http.get(`${this.dashboardApi}/get-chart-by-organization`, {});
  }

  getChartByField(periodId: string) : Observable<any>{
    return this.http.get(`${this.dashboardApi}/get-chart-by-field?periodId=${periodId}`, {});
  }

}
