
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class RoleService {

    roleApi = `${this.apiConstants.serviceEndpoint}/Role`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getRole(id: string) : Observable<any>{
        return this.http.get(`${this.roleApi}/get-role/${id}`);
    }

    getRoles(body: any) : Observable<any>{
        return this.http.post(`${this.roleApi}/get-roles`, body);
    }

    createRole(body: any) {
        return this.http.post(`${this.roleApi}/create-role`, body);
    }

    updateRole(body: any) {
        return this.http.put(`${this.roleApi}/update-role/${body.id}`, body);
    }

    deleteRole(id: string): Promise<any> {
        return this.http.delete(`${this.roleApi}/delete-role/${id}`).toPromise();
    }

    deleteRoles(body: string[]): Promise<any> {
        return this.http.post(`${this.roleApi}/delete-roles`, body).toPromise();
    }
}
