
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class AnnouncementService {

    announcementApi = `${this.apiConstants.serviceEndpoint}/Announcement`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getAnnouncement(id: string) : Observable<any>{
        return this.http.get(`${this.announcementApi}/get-announcement/${id}`);
    }

    getAnnouncements(body: any) : Observable<any>{
        return this.http.post(`${this.announcementApi}/get-announcements`, body);
    }

    createAnnouncement(body: any) {
        return this.http.post(`${this.announcementApi}/create-announcement`, body);
    }

    updateAnnouncement(body: any) {
        return this.http.put(`${this.announcementApi}/update-announcement/${body.id}`, body);
    }

    deleteAnnouncement(id: string): Promise<any> {
        return this.http.delete(`${this.announcementApi}/delete-announcement/${id}`).toPromise();
    }

    deleteAnnouncements(body: string[]): Promise<any> {
        return this.http.post(`${this.announcementApi}/delete-announcements`, body).toPromise();
    }
}
