import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_TOKEN } from '../../auth';
import { ApiConstant } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userApi = `${this.apiConstants.endpoint}/api/User`;
  accountApi = `${this.apiConstants.endpoint}/Account`;

  constructor(
    @Inject(API_TOKEN) private apiConstants: ApiConstant,
    private http: HttpClient
  ) {}

  getUser(id: string): Observable<any> {
    return this.http.get(`${this.userApi}/get-user/${id}`);
  }

  getUsers(body: any): Observable<any> {
    return this.http.post(`${this.userApi}/get-users`, body);
  }

  createUser(body: any) {
    return this.http.post(`${this.userApi}/create-user`, body);
  }

  updateUser(body: any) {
    return this.http.put(`${this.userApi}/update-user`, body);
  }

  deleteUser(userId: string): Promise<any> {
    return this.http
      .delete(`${this.userApi}/delete-user?deletedUserId=${userId}`)
      .toPromise();
  }

  changePassword(body): Observable<any> {
    return this.http.post(`${this.accountApi}/self-change-password`, body);
  }

  requestResetPassword(body): Observable<any> {
    return this.http.post(`${this.accountApi}/request-reset-password`, body);
  }

  resetPassword(body): Promise<any> {
    return this.http.post(`${this.accountApi}/reset-password`, body).toPromise();
  }

  activateUser(body): Promise<any> {
    return this.http.post(`${this.accountApi}/activate-user`, body).toPromise();
  }

  lockUser(body): Promise<any> {
    return this.http.post(`${this.accountApi}/lock-user`, body).toPromise();
  }
}
