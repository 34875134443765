
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class UserRoleService {

    userRoleApi = `${this.apiConstants.serviceEndpoint}/UserRole`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getUserRole(id: string) : Observable<any>{
        return this.http.get(`${this.userRoleApi}/get-user-role/${id}`);
    }

    getUserRoles(body: any) : Observable<any>{
        return this.http.post(`${this.userRoleApi}/get-user-roles`, body);
    }

    createUserRole(body: any) {
        return this.http.post(`${this.userRoleApi}/create-user-role`, body);
    }

    updateUserRole(body: any) {
        return this.http.put(`${this.userRoleApi}/update-user-role/${body.id}`, body);
    }

    deleteUserRole(id: string): Promise<any> {
        return this.http.delete(`${this.userRoleApi}/delete-user-role/${id}`).toPromise();
    }

    deleteUserRoles(body: string[]): Promise<any> {
        return this.http.post(`${this.userRoleApi}/delete-user-roles`, body).toPromise();
    }
}
