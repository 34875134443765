import {CommonModule} from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {QuicklinkModule} from 'ngx-quicklink';
import { ApiConstant } from '../types';
import {AppBreadcrumbModule} from './app-breadcrumb';
import {AppErrorBoxModule} from './app-error-box';
import { ComponentsModule } from './components/components.module';
import {DataTableModule} from './datatable';
import {DataTableMobileModule} from './datatable-mobile';
import { CustomHttpInterceptor } from './interceptor/custom-http.interceptor';
import {MaterialModule} from './material.module';
import { CampaignService } from './services';
import { API_SHARED_TOKEN } from './types';

@NgModule({
    imports: [CommonModule, QuicklinkModule, MaterialModule],
    exports: [
        AppBreadcrumbModule,
        AppErrorBoxModule,
        MaterialModule,
        QuicklinkModule,
        DataTableModule,
        DataTableMobileModule,
        ComponentsModule
    ],
})
export class SharedModule {
    static forRoot(apiConstantt: ApiConstant): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                { provide: API_SHARED_TOKEN, useValue: apiConstantt },    
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: CustomHttpInterceptor,
                    multi: true,
                },         
                CampaignService,
            ],
        };
    }
}
