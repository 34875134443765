<router-outlet></router-outlet>

<!-- <div class="maintain-notice">
  <div class="">
    <h1>Hệ thống đang bảo trì, vui lòng quay lại sau....</h1>
  </div>
  <div class="">
    <h5>Thời gian hoạt động trở lại dự kiến: 08h00 30/06/2024 </h5>
  </div>
</div> -->
