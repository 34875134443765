<div class="home d-flex flex-column h-100">
  <div class="home-header d-flex align-items-center justify-content-between">
    <div class="home-logo">
      <img src="assets/images/main-logo.png" />
      <div>
        <div class="name">PHẦN MỀM QUẢN LÝ SÁNG KIẾN KINH NGHIỆM THÀNH PHỐ HÀ NỘI</div>
        <div class="slogan">SỞ KHOA HỌC VÀ CÔNG NGHỆ</div>
      </div>
    </div>
    <button *ngIf="!user?.id" class="btn-basic btn-blue btn-logout" routerLink="/auth/login">
      Login
    </button>
    <button *ngIf="user?.id" class="btn-basic btn-blue btn-logout" (click)="Logout()">
      Logout
    </button>
  </div>
  <div class="home-content flex-grow-1 d-flex align-items-center justify-content-center flex-column">
    <p class="home-welcome ">
      Welcome
      <span class="home-username">{{ user?.firstname }} {{ user?.lastname }}</span>
    </p>
    <div>
      <!-- <button class="btn-basic btn-white" (click)="redirectToLink('/waiter-app')">Waiter App</button> -->
      <button class="btn-basic btn-blue btn-dashboard" (click)="redirectToLink('/administrator/dashboard')">
        Dashboard
      </button>
    </div>
  </div>
</div>