import {
  Component,
  Renderer2,
  ViewChild,
  OnInit,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';

const ALLOW_CLICK_CLASSNAME = [
  'mat-option-text',
  'mat-calendar-body-cell-content',
  'cdk-overlay-backdrop',
  'mat-filter-input',
  'mat-calendar-button-2',
  'mat-calendar-previous-button',
  'mat-calendar-next-button',
  'mat-calendar-body-label',
  'mat-calendar-period-button',
  'mat-calendar-button-0',
  'mat-button-wrapper',
  'mat-calendar-header',
  'mat-calendar-table',
  'mat-calendar-body-cell',
  'mat-calendar-body',
  'mat-filter',
  'mat-select-panel',
  'noResultsMessage',
  'cdk-overlay-container',
  'mat-tooltip',
  'mat-tooltip-show',
  'mat-select-value'
];

@Component({
  selector: 'filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawer: any;
  @ViewChild('panel') panel;

  @Output() onSearch = new EventEmitter<string>();

  @Output() onReset = new EventEmitter<string>();

  showFiller = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.listen('window', 'click', e => {
      if (this.isClickOutsidePanel(e)) {
        this.showHide();
      }
    });
  }

  isClickOutsidePanel(e) {
    if (!this.showFiller) {
      return;
    }
    const condition_1 =
      this.panel?._element?.nativeElement &&
      !this.panel._element.nativeElement.contains(e.target);
    const condition_2 = this.showFiller;
    const condition_3 = this.drawer.opened;
    var classList = Array.from(e.target.classList) as string[];
    const condition_4 =
      classList?.some(x => ALLOW_CLICK_CLASSNAME.includes(x)) ||
      ALLOW_CLICK_CLASSNAME.includes(e.target.id);
    return condition_1 && condition_2 && condition_3 && !condition_4;
  }

  showHide() {
    setTimeout(() => {
      this.showFiller = !this.showFiller;
      this.drawer.toggle();
    }, 10);
  }

  close() {
    this.showFiller = false;
    this.drawer.toggle();
  }

  reset() {
    this.onReset && this.onReset.emit();
    this.close();
  }

  search() {
    this.onSearch && this.onSearch.emit();
    this.close();
  }
}
