import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_TOKEN } from '../../auth';
import { ApiConstant } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  allOrganization = [];
  treeOrganization;

  organizationApi = `${this.apiConstants.serviceEndpoint}/Organization`;

  constructor(
    @Inject(API_TOKEN) private apiConstants: ApiConstant,
    private http: HttpClient
  ) {}

  getOrganization(id: string): Observable<any> {
    return this.http.get(`${this.organizationApi}/get-organization/${id}`);
  }

  getOrganizations(body: any): Observable<any> {
    return this.http.post(`${this.organizationApi}/get-organizations`, body);
  }

  createOrganization(body: any) {
    return this.http.post(`${this.organizationApi}/create-organization`, body);
  }

  updateOrganization(body: any) {
    return this.http.put(
      `${this.organizationApi}/update-organization/${body.id}`,
      body
    );
  }

  deleteOrganization(id: string): Promise<any> {
    return this.http
      .delete(`${this.organizationApi}/delete-organization/${id}`)
      .toPromise();
  }

  deleteOrganizations(body: string[]): Promise<any> {
    return this.http
      .post(`${this.organizationApi}/delete-organizations`, body)
      .toPromise();
  }

  getListOrganization() {
    return new Promise<any[]>(resolve => {
      const body = {
        getAll: true,
        totalItems: 0,
        sortName: 'createdDate',
        sortType: 0,
        filters: []
      };
      this.getOrganizations(body).subscribe(res => {
        this.allOrganization = res.datas;
        resolve(res.datas as any[]);
      });
    });
  }

  async buildTreeOrganization(organizations?: any[]) {
    let data = organizations;
    if (!data) {
      data = await this.getListOrganization();
    }
    let treeOrganizations = data.map(organization => ({
      value: organization,
      children: [],
      childrenCountDeep: 0
    }));
    treeOrganizations.forEach(s => {
      s.children = treeOrganizations.filter(
        x => x.value.parentId === s.value.id
      );
      s.childrenCountDeep = this.getChildrenCountDeep(s.value);
    });
    treeOrganizations = treeOrganizations.filter(
      s => s.value.parentId === null
    );

    return treeOrganizations;
  }

  getChildrenCountDeep(org: any): number {
    let result = 0;
    const children = this.allOrganization.filter(o => o.parentId === org.id);
    result = children.length;
    children.forEach(o => {
      result += this.getChildrenCountDeep(o);
    });
    return result;
  }

  async getTreeOrganization() {
    if (this.treeOrganization) {
      return this.treeOrganization;
    }
    return await this.buildTreeOrganization();
  }
}
