
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
  providedIn: "root"
})
export class ReportService {

  reportApi = `${this.apiConstants.serviceEndpoint}/Report`;

  constructor(
    @Inject(API_TOKEN) private apiConstants: ApiConstant,
    private http: HttpClient
  ) { }
  checkTotalReviewByPeriod(body: any) : Observable<any>{
    return this.http.post(`${this.reportApi}/check-total-review-by-period`, body);
  }

  totalReviewByPeriod(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/total-review-by-period`, body, {
      responseType: 'blob' as 'json'
    });
  }

  getDataTotalReviewByPeriod(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/get-data-total-review-by-period`, body);
  }

  checkTotalInitiativeByPeriod(body: any): Observable<any>{
    return this.http.post(`${this.reportApi}/check-total-initiative-by-period`, body);
  }

  totalInitiativeByPeriod(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/total-initiative-by-period`, body, {
      responseType: 'blob' as 'json'
    });
  }

  getDataTotalInitiativeByPeriod(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/get-data-total-initiative-by-period`, body);
  }

  checkExportEvaluationForm(body: any): Observable<any>{
    return this.http.post(`${this.reportApi}/check-export-evaluation-form`, body);
  }

  exportEvaluationForm(body: any): Observable<any> {
    if (body.type === 1) {
      return this.http.post(`${this.reportApi}/export-evaluation-form`, body, {
        responseType: 'blob' as 'json'
      });
    } else {
      return this.http.post(`${this.reportApi}/export-assessment-form`, body, {
        responseType: 'blob' as 'json'
      });
    }
  }

  exportEvaluationFormByInitiatives(body: any): Observable<any> {
    if (body.type === 1) {
      return this.http.post(`${this.reportApi}/export-evaluation-form-by-initiatives`, body, {
        responseType: 'blob' as 'json'
      });
    } else {
      return this.http.post(`${this.reportApi}/export-assessment-form-by-initiatives`, body, {
        responseType: 'blob' as 'json'
      });
    }
  }

  getDataExportEvaluationForm(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/get-data-export-evaluation-form`, body);
  }

  checkExportProfessionalCouncilMeeting(body: any): Observable<any>{
    return this.http.post(`${this.reportApi}/check-export-professional-council-meeting`, body);
  }

  exportProfessionalCouncilMeeting(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/export-professional-council-meeting`, body, {
      responseType: 'blob' as 'json'
    });
  }

  getDataExportProfessionalCouncilMeeting(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/get-data-export-professional-council-meeting`, body );
  }

  exportRecognitionDecisions(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/export-recognition-decisions`, body, {
      responseType: 'blob' as 'json'
    });
  }

  getDataExportRecognitionDecisions(body: any): Observable<any> {
    return this.http.post(`${this.reportApi}/get-data-export-recognition-decisions`, body );
  }
}
