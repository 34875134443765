import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLazyModule } from './auth-lazy.module';
import { AuthVerifyCodeComponent } from './auth-verify-code/auth-verify-code.component';
import { AuthLoginContainer } from './containers';
import { AuthRegisterContainer } from './containers/auth-register.container';

const routes: Routes = [
    {
        path: 'login',
        component: AuthLoginContainer
    },
    {
        path: 'register',
        component: AuthRegisterContainer
    },
    {
        path: 'active-store',
        component: AuthRegisterContainer
    },
    {
        path: 'verify-code',
        component: AuthVerifyCodeComponent
    },
];

@NgModule({
    imports: [CommonModule, AuthLazyModule, RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
