
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class CouncilDocumentService {

    councilDocumentApi = `${this.apiConstants.serviceEndpoint}/CouncilDocument`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getCouncilDocument(id: string) : Observable<any>{
        return this.http.get(`${this.councilDocumentApi}/get-council-document/${id}`);
    }

    getCouncilDocuments(body: any) : Observable<any>{
        return this.http.post(`${this.councilDocumentApi}/get-council-documents`, body);
    }

    createCouncilDocument(body: any, headers: any) {
        return this.http.post(`${this.councilDocumentApi}/create-council-document`, body, {headers: headers});
    }

    updateCouncilDocument(body: any) {
        return this.http.put(`${this.councilDocumentApi}/update-council-document/${body.id}`, body);
    }

    deleteCouncilDocument(id: string): Promise<any> {
        return this.http.delete(`${this.councilDocumentApi}/delete-council-document/${id}`).toPromise();
    }

    deleteCouncilDocuments(body: string[]): Promise<any> {
        return this.http.post(`${this.councilDocumentApi}/delete-council-documents`, body).toPromise();
    }

    downloadCouncilDocument(id: string) : Observable<any>{
        return this.http.get(`${this.councilDocumentApi}/download/${id}`, {
            responseType: 'blob' as 'json'
        });
    }
}
