'use strict';

export const menuItems = [
  // {
  //   id: 1,
  //   parentId: 0,
  //   text: 'Dashboard',
  //   url: '/administrator/dashboard',
  //   role: ['admin'],
  //   hasChild: false,
  //   valid: 0,
  //   icon: '../assets/images/menu-icons/pra-manager.svg',
  //   activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
  //   isOpened: false,
  //   name: 'dashboard',
  //   byPassPermission: true,
  // },
  // {
  //   id: 2,
  //   parentId: 0,
  //   text: 'Sáng kiến kinh nghiệm',
  //   url: '/administrator/dashboard',
  //   role: ['admin'],
  //   hasChild: false,
  //   valid: 0,
  //   icon: '../assets/images/menu-icons/pra-manager.svg',
  //   activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
  //   isOpened: false,
  //   name: 'homepage_setting',
  //   byPassPermission: true,
  // },
  // {
  //   id: 3,
  //   parentId: 0,
  //   text: 'Danh mục',
  //   url: '/administrator/homepage-settings',
  //   role: ['admin'],
  //   hasChild: false,
  //   valid: 0,
  //   icon: '../assets/images/menu-icons/pra-manager.svg',
  //   activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
  //   isOpened: false,
  //   name: 'homepage_setting',
  //   byPassPermission: true,
  // },
  // {
  //   id: 3,
  //   parentId: 0,
  //   text: 'Quản trị',
  //   url: '/administrator/homepage-settings',
  //   role: ['admin'],
  //   hasChild: false,
  //   valid: 0,
  //   icon: '../assets/images/menu-icons/pra-manager.svg',
  //   activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
  //   isOpened: false,
  //   name: 'homepage_setting',
  //   byPassPermission: true,
  // },
];
export const initiativeExpMenu = [
  {
    id: 1,
    parentId: 0,
    text: 'Đơn đăng ký',
    url: '/administrator/general/initiative-experience/registration-form',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'registration_form',
    byPassPermission: true,
  },
  {
    id: 2,
    parentId: 0,
    text: 'Quản lý SKKN',
    url: '/administrator/general/initiative-experience/management-skkn',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'management_skkn',
    byPassPermission: true,
  },
  {
    id: 3,
    parentId: 0,
    text: 'Báo cáo',
    url: '/administrator/general/initiative-experience/report',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'report',
    byPassPermission: true,
  },
];

export const categoryMenu = [
  {
    id: 1,
    parentId: 0,
    text: 'Quản lý loại đơn vị',
    url: '/administrator/general/major',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'management_unit_type',
    byPassPermission: true,
  },
  {
    id: 2,
    parentId: 0,
    text: 'Quản lý đơn vị',
    url: '/administrator/general/organization',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'management_unit',
    byPassPermission: true,
  },
  {
    id: 3,
    parentId: 0,
    text: 'Quản lý lĩnh vực',
    url: '/administrator/general/initiative-field',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'management-field',
    byPassPermission: true,
  },
  {
    id: 4,
    parentId: 0,
    text: 'Quản lý năm làm việc',
    url: '/administrator/general/working-year',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'management_academic_year',
    byPassPermission: true,
  },
  {
    id: 5,
    parentId: 0,
    text: 'Quản lý văn bản',
    url: '/administrator/general/office-document',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'management_document',
    byPassPermission: true,
  },
]

export const managementMenu = [
  {
    id: 1,
    parentId: 0,
    text: 'Quản lý người dùng',
    url: '/administrator/general/user',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'management_unit_type',
    byPassPermission: true,
  },
  {
    id: 2,
    parentId: 0,
    text: 'Quản lý nhóm người dùng',
    url: '/administrator/general/group',
    role: ['admin'],
    hasChild: false,
    valid: 0,
    icon: '../assets/images/menu-icons/pra-manager.svg',
    activeIcon: '../assets/images/menu-icons/pra-manager-active.svg',
    isOpened: false,
    name: 'management_unit_type',
    byPassPermission: true,
  },
]
