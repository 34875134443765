
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class InitiativeMemberService {

    initiativeMemberApi = `${this.apiConstants.serviceEndpoint}/InitiativeMember`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getInitiativeMember(id: string) : Observable<any>{
        return this.http.get(`${this.initiativeMemberApi}/get-initiative-member/${id}`);
    }

    getInitiativeMembers(body: any) : Observable<any>{
        return this.http.post(`${this.initiativeMemberApi}/get-initiative-members`, body);
    }

    createInitiativeMember(body: any) {
        return this.http.post(`${this.initiativeMemberApi}/create-initiative-member`, body);
    }

    updateInitiativeMember(body: any) {
        return this.http.put(`${this.initiativeMemberApi}/update-initiative-member/${body.id}`, body);
    }

    deleteInitiativeMember(id: string): Promise<any> {
        return this.http.delete(`${this.initiativeMemberApi}/delete-initiative-member/${id}`).toPromise();
    }

    deleteInitiativeMembers(body: string[]): Promise<any> {
        return this.http.post(`${this.initiativeMemberApi}/delete-initiative-members`, body).toPromise();
    }
}
