
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class WorkingYearService {

    workingYearApi = `${this.apiConstants.serviceEndpoint}/WorkingYear`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getWorkingYear(id: string) : Observable<any>{
        return this.http.get(`${this.workingYearApi}/get-working-year/${id}`);
    }

    getWorkingYears(body: any) : Observable<any>{
        return this.http.post(`${this.workingYearApi}/get-working-years`, body);
    }

    createWorkingYear(body: any) {
        return this.http.post(`${this.workingYearApi}/create-working-year`, body);
    }

    updateWorkingYear(body: any) {
        return this.http.put(`${this.workingYearApi}/update-working-year/${body.id}`, body);
    }

    deleteWorkingYear(id: string): Promise<any> {
        return this.http.delete(`${this.workingYearApi}/delete-working-year/${id}`).toPromise();
    }

    deleteWorkingYears(body: string[]): Promise<any> {
        return this.http.post(`${this.workingYearApi}/delete-working-years`, body).toPromise();
    }
}
