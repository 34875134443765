
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class AppSettingService {

    appSettingApi = `${this.apiConstants.serviceEndpoint}/AppSetting`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getAppSetting(id: string) : Observable<any>{
        return this.http.get(`${this.appSettingApi}/get-app-setting/${id}`);
    }

    getAppSettingByKey(key: string) : Observable<any>{
        return this.http.get(`${this.appSettingApi}/get-app-setting-by-key/${key}`);
    }

    getAppSettings(body: any) : Observable<any>{
        return this.http.post(`${this.appSettingApi}/get-app-settings`, body);
    }

    createAppSetting(body: any) {
        return this.http.post(`${this.appSettingApi}/create-app-setting`, body);
    }

    updateAppSetting(body: any) {
        return this.http.put(`${this.appSettingApi}/update-app-setting/${body.id}`, body);
    }

    deleteAppSetting(id: string): Promise<any> {
        return this.http.delete(`${this.appSettingApi}/delete-app-setting/${id}`).toPromise();
    }

    deleteAppSettings(body: string[]): Promise<any> {
        return this.http.post(`${this.appSettingApi}/delete-app-settings`, body).toPromise();
    }
}
