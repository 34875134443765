import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { last, uniqBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import * as uuid from 'uuid';
import { AppSettingService, InitiativeDocumentService } from '../../services';
import { DialogWarningComponent } from '../dialog-warning/dialog-warning.component';
import { DialogPreviewDocumentComponent } from './preview/preview.component';

const FIZE_SIZE_LIMIT = 100000000;

export interface AttachmentDataTable {
  documentName: string;
  files?: any;
  multiple?: boolean;
  type: InitiativeDocumentType;
  required?: boolean;
}

enum InitiativeDocumentType {
  /** skkn */
  Initiative = 0,
  /** Tờ trình */
  Statement = 1,
  /** Biên bản của Hội Đồng sáng kiến cơ sở */
  MinutesOfTheGrassrootsInitiativeCouncil = 2,
  /** Quyết định hoặc Giấy chứng nhận sáng kiến của cơ sở xét, công nhận sáng kiến */
  DecisionOrCertificateOfInitiativeOfTheEstablishmentConsideringAndRecognizingTheInitiative = 3,
  /** Báo cáo tóm tắt */
  SummaryReport = 4,
  /** Tài liệu kèm thêm */
  AdditionalDocuments = 5
}

@Component({
  selector: 'attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {
  _initiativeId?: string;

  @Input() disableUpload: boolean = false;

  @Input() set initiativeId(value: string) {
    if (value) {
      this._initiativeId = value;
      this.getUploadedFile();
    }
  }

  @ViewChild('tableDocuments', { static: false }) tableDocuments: MatTable<any>;

  requirementDocuments: AttachmentDataTable[] = [
    {
      documentName: 'Tờ trình',
      type: InitiativeDocumentType.Statement
    },
    {
      documentName: 'Biên bản họp xét của Hội đồng Sáng kiến cấp cơ sở',
      type: InitiativeDocumentType.MinutesOfTheGrassrootsInitiativeCouncil
    },
    {
      documentName:
        'Quyết định hoặc Giấy chứng nhận sáng kiến cấp cơ sở',
      type:
        InitiativeDocumentType.DecisionOrCertificateOfInitiativeOfTheEstablishmentConsideringAndRecognizingTheInitiative
    },
    {
      documentName: 'Báo cáo sáng kiến, đề tài khoa học (theo mẫu)',
      type: InitiativeDocumentType.SummaryReport
    },
    {
      documentName: 'Các tài liệu, minh chứng liên quan',
      type: InitiativeDocumentType.Initiative
    },
    // {
    //   documentName: 'Tài liệu kèm thêm',
    //   type: InitiativeDocumentType.AdditionalDocuments,
    //   multiple: true
    // }
  ];

  columns = ['index', 'documentName', 'files'];

  isLoading = false;

  fileList = {};

  uploadedFile = [];

  requireLength = 0;

  constructor(
    private initiativeDocumentService: InitiativeDocumentService,
    private toast: ToastrService,
    public dialog: MatDialog,
    private appSettingService: AppSettingService
  ) {}

  ngOnInit() {
    this.getAppSetting();
  }

  getAppSetting() {
    this.appSettingService
      .getAppSettingByKey('MinFilesLength')
      .subscribe(res => {
        if (res.value && res.isActive) {
          const parsed = parseInt(res.value);
          if (parsed) {
            this.requireLength = parsed;
            let count = 0;
            this.requirementDocuments.map(item => {
              if (count < parsed) {
                item.required = true;
                count ++;
              }
              return item;
            });
          }
        }
      });
  }

  initAttachment() {}

  getUploadedFile() {
    this.initiativeDocumentService
      .getByInitiativePost(this._initiativeId)
      .subscribe(res => {
        this.uploadedFile = res;
        res.forEach(initiativeDocument => {
          if (initiativeDocument.id) {
            // this.getMimeFile(item);
            const file = new File([], initiativeDocument.name);
            const existedFile =
              this.fileList[initiativeDocument.documentType]?.files || [];
            existedFile.push({
              file,
              fileType: this.getFileType(file.name),
              id: initiativeDocument.id,
              canPreview: true
            });
            this.fileList[initiativeDocument.documentType] =
              this.fileList[initiativeDocument.documentType] || {};
            this.fileList[initiativeDocument.documentType][
              'files'
            ] = existedFile;
            this.tableDocuments.renderRows();
          }
        });
      });
  }

  async getMimeFile(initiativeDocument: any) {
    return new Promise<File>((resolve, reject) => {
      this.initiativeDocumentService
        .downloadInitiativeDocument(initiativeDocument.id)
        .subscribe(
          blob => {
            const file = new File([blob], initiativeDocument.name);
            resolve(file);
          },
          err => {
            reject();
          }
        );
    });
  }

  uploadFile($event, type: InitiativeDocumentType, multiple?: boolean) {
    const files = $event.target.files as File[];

    if (!files.length) {
      return;
    }

    const validFiles = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const fileType = this.getFileType(file.name);
      const valid = this.validateFile(file, fileType, type);
      if (valid) {
        validFiles.push({
          file,
          fileType,
          id: uuid()
        });
      }
    }

    const existedFile =
      this.fileList[type] && multiple ? this.fileList[type].files : [];
    const newFile = [...existedFile, ...validFiles];
    this.fileList[type] = {
      files: uniqBy(newFile, 'file.name')
    };
    this.tableDocuments.renderRows();
  }

  validateFile(file: File, fileType: string, type: InitiativeDocumentType) {
    let errorMsg = '';

    if (file.size > FIZE_SIZE_LIMIT) {
      errorMsg = 'Dung lượng file không vượt quá 80MB';
    }

    if (fileType === 'other') {
      errorMsg = 'Chỉ cho phép các dạng file: word, pdf, ảnh, pptx, excel';
    }

    if (errorMsg) {
      this.toast.error(errorMsg);
    }
    return !errorMsg;
  }

  getFileType(fileName: string) {
    const extension = last(fileName.split('.'));
    let iconType = '';
    switch (extension) {
      case 'doc':
      case 'docx':
        iconType = 'word';
        break;
      case 'xls':
      case 'xlsx':
        iconType = 'excel';
        break;
      case 'ppt':
      case 'pptx':
        iconType = 'ppt';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        iconType = 'image';
        break;
      case 'pdf':
        iconType = 'pdf';
        break;
      default:
        iconType = 'other';
        break;
    }
    return iconType;
  }

  removeFile(type: InitiativeDocumentType, fileId: string) {
    if (this.isMutiple(type)) {
      this.fileList[type].files = this.fileList[type].files.filter(
        f => f.id !== fileId
      );
    } else {
      this.fileList[type] = undefined;
    }
  }

  async sync(initiativeId: string) {
    if (!this._initiativeId && !initiativeId) {
      return;
    }
    this._initiativeId = initiativeId;
    this.isLoading = true;
    const classified = this.classifyFile();
    classified.add.forEach(item => {
      try {
        this.sendRequest(item.file, item.type, 'add');
      } catch (er) {}
    });
    classified.delete.forEach(item => {
      try {
        this.sendRequest(item.file, item.type, 'delete', item.id);
      } catch (er) {}
    });

    this.isLoading = false;
  }

  /** Phân loại file trước khi sync */
  classifyFile() {
    const newFiles = [];
    const keepOrdeleteFiles = [];
    Object.keys(this.fileList).forEach(key => {
      this.fileList[key]?.files?.forEach(item => {
        if (this.uploadedFile.some(x => x.id === item.id)) {
          keepOrdeleteFiles.push({ ...item, type: Number(key) });
        } else {
          newFiles.push({ ...item, type: Number(key) });
        }
      });
    });
    const deleteFiles = this.uploadedFile.filter(
      item => !keepOrdeleteFiles.some(x => x.id === item.id)
    );
    return {
      delete: deleteFiles,
      add: newFiles
    };
  }

  async sendRequest(
    file: File,
    type: InitiativeDocumentType,
    action: 'add' | 'delete',
    fileId?: string
  ) {
    if (action === 'add') {
      const req = {
        initiativeId: this._initiativeId,
        documentType: type
      };
      let headers = new Headers();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      Object.keys(req).forEach(key => {
        formData.append(key, req[key]);
      });
      await this.initiativeDocumentService
        .createInitiativeDocument(formData, headers)
        .toPromise();
    }
    if (action === 'delete' && fileId) {
      this.initiativeDocumentService.deleteInitiativeDocument(fileId);
    }
  }

  isMutiple(type: InitiativeDocumentType) {
    return this.requirementDocuments.find(x => x.type == type)?.multiple;
  }

  isRequired(type: InitiativeDocumentType) {
    return this.requirementDocuments.find(x => x.type == type)?.required;
  }

  isValid() {
    let valid = true;
    let totalFile = 0;
    this.requirementDocuments.forEach(item => {
      if (item.required && !this.fileList[item.type]?.files?.length) {
        valid = false;
      }
      totalFile += this.fileList[item.type]?.files?.length || 0;
    });
    if (valid) {
      valid = totalFile >= this.requireLength;
    }
    return valid;
  }

  async downloadFile(record) {
    try {
      const file = await this.getMimeFile(record);
      var a = document.createElement('a');
      document.body.appendChild(a);
      const blob = new Blob([file], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = record.file.name;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch {}
  }

  confirmRemoveFile(type: InitiativeDocumentType, fileId: string) {
    const dialogWarningMedia = this.dialog.open(DialogWarningComponent, {
      width: '512px',
      data: {
        typeWarning: 'CONFIRM_DELETE',
        dataWarning: 'Xác nhận xóa file này?'
      }
    });
    dialogWarningMedia.afterClosed().subscribe(async res => {
      if (res == 'CONFIRM_DELETE') {
        this.removeFile(type, fileId);
      }
    });
  }

  preview(fileItem) {
    const dialogRef = this.dialog.open(DialogPreviewDocumentComponent, {
      width: '80%',
      panelClass: 'preview-dialog',
      data: {
        fileItem
      }
    });
    dialogRef.afterClosed().subscribe(async res => {});
  }
}
