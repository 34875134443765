
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class VillageService {

    villageApi = `${this.apiConstants.serviceEndpoint}/Village`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getVillage(id: string) : Observable<any>{
        return this.http.get(`${this.villageApi}/get-village/${id}`);
    }

    getVillages(body: any) : Observable<any>{
        return this.http.post(`${this.villageApi}/get-villages`, body);
    }

    createVillage(body: any) {
        return this.http.post(`${this.villageApi}/create-village`, body);
    }

    updateVillage(body: any) {
        return this.http.put(`${this.villageApi}/update-village/${body.id}`, body);
    }

    deleteVillage(id: string): Promise<any> {
        return this.http.delete(`${this.villageApi}/delete-village/${id}`).toPromise();
    }

    deleteVillages(body: string[]): Promise<any> {
        return this.http.post(`${this.villageApi}/delete-villages`, body).toPromise();
    }
}
