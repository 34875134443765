import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import { OrganizationService } from "../../services";
import { FlatTreeControl, NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from "@angular/material/tree";

interface OrganizationFlatNode {
  expandable: boolean;
  value: any;
  level: number;
  childrenCountDeep: number;
}

interface OrganizationNode {
  value: any;
  children?: OrganizationNode[];
  childrenCountDeep: number
}


@Component({
  selector: "tree-organization-select",
  templateUrl: "./tree-organization-select.component.html",
  styleUrls: ["./tree-organization-select.component.scss"]
})
export class TreeOrganizationSelectComponent implements OnInit, OnDestroy {
  @Input() options: any[] = [];

  @Input() selectedOption: string;

	@Output() select = new EventEmitter<any>();

	currentOption: any;

  isOpen = false;
	@ViewChild('optionsEl') optionsEl: ElementRef<HTMLDivElement>;

	

	constructor(
		private organizationService: OrganizationService
	) {
		// this.dataSource.data = TREE_DATA;
	}

  ngOnInit(): void {
		this.currentOption = this.options.find(option => option.image === this.selectedOption) ?? this.options[0];
		window.addEventListener('click', this.handleClick);
		this.getTreeData();
  }

	async getTreeData() {
		this.dataSource.data = await this.organizationService.getTreeOrganization();
	}

	ngOnChanges() {
		this.currentOption = this.options.find(option => option.image === this.selectedOption) ?? this.options[0];
	}


	ngOnDestroy(): void {
		window.removeEventListener('click', this.handleClick);
	}

	handleClick = (e: MouseEvent) => {
		if (!this.isOpen) return;
		if (!this.optionsEl.nativeElement.contains(e.target as Node)) {
			this.isOpen = false;
		}
	}

	openOptions(e: any) {
		e.preventDefault();
		e.stopPropagation();
		this.isOpen = !this.isOpen;
	}

	onSelect(e: any, key: string) {
		e.preventDefault();
		e.stopPropagation();
		this.currentOption = this.options.find(option => option.key === key) ?? this.options[0];
		this.isOpen = false;
		this.select.emit(this.currentOption);
	}

	private transformer = (node: OrganizationNode, level: number): OrganizationFlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      value: node.value,
      level: level,
      childrenCountDeep: node.childrenCountDeep
    };
  }

	treeControl = new FlatTreeControl<OrganizationFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level,
    node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: OrganizationFlatNode) => node.expandable;
}
