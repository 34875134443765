import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  CustomEncoder,
  Logger,
  MapRouteService,
  SKKNUserInfo,
  UserInfo
} from '../../core';
import { ApiConstant, CustomHttpErrorResponse } from '../../types';
import {
  API_TOKEN,
  AuthenticateInfo,
  NotificationResult,
  RegisterActiveInput,
  RegisterStoreInput,
  TokenPayload
} from '../types';
import { UserService } from './user.service';
import { isAuthorized, getJWT, getRole } from './jwt-helper';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class AuthService {
  authUrl = `${this.apiConstants.endpoint}/Account`;
  private errorsSub$ = new Subject<string[]>();

  private isLoginSub$ = new BehaviorSubject(this.isLogin());
  isLogin$ = this.isLoginSub$.asObservable();

  private userSub$ = new BehaviorSubject<UserInfo | null>(this.getUser());
  user$ = this.userSub$.asObservable();

  private loginErrorSub$ = new Subject<string[]>();
  loginError$ = this.loginErrorSub$.asObservable();

  public registerErrorSub$ = new Subject<string[]>();
  registerError$ = this.registerErrorSub$.asObservable();

  public activeErrorSub$ = new Subject<string[]>();
  activeError$ = this.activeErrorSub$.asObservable();

  private contactUsErrorSub$ = new Subject<string[]>();
  contactUsError$ = this.contactUsErrorSub$.asObservable();

  private closeSideNavSub$ = new Subject();
  closeSideNav$ = this.closeSideNavSub$.asObservable();

  private tmpRedirectUrl = '';

  private checkAccessSub$ = new Subject<boolean>();

  private disableBlockchainSub$ = new Subject<boolean>();
  disableBlockchain$ = this.disableBlockchainSub$.asObservable();

  private menuNotificationSub$ = new Subject<NotificationResult>();
  menuNotification$ = this.menuNotificationSub$.asObservable();

  private permissionsSub = new BehaviorSubject<any>(undefined);
  permissionsSub$ = this.permissionsSub.asObservable();

  private menuItemsSub = new BehaviorSubject<any>([]);
  menuItemsSub$ = this.menuItemsSub.asObservable();

  private handleError(err: CustomHttpErrorResponse) {
    if (err.errorJson && err.errorJson.message) {
      this.errorsSub$.next(err.errorJson.message);
    } else {
      this.errorsSub$.next([err.message]);
    }
    return EMPTY;
  }

  constructor(
    @Inject(API_TOKEN) private apiConstants: ApiConstant,
    private httpClient: HttpClient,
    private router: Router,
    public translate: TranslateService,
    private userService: UserService,
    private mapRouteService: MapRouteService
  ) {}

  get redirectUrl() {
    return this.tmpRedirectUrl;
  }

  set redirectUrl(value: string) {
    this.tmpRedirectUrl = value;
  }

  get checkAccess$() {
    return this.checkAccessSub$.asObservable();
  }

  login(input: AuthenticateInfo) {
    const headers = new HttpHeaders({
      [TRANSACTION_NAME]: 'Login'
    });
    return this.httpClient
      .post(`${this.authUrl}/login-account`, input, {
        responseType: 'text',
        headers
      })
      .pipe(
        tap((res: any) => {
          this.initDataAfterLogin(res);
        }),
        catchError((err: CustomHttpErrorResponse) => {
          this.isLoginSub$.next(false);
          if (err.errorJson) {
            this.loginErrorSub$.next(err.errorJson.message);
          } else {
            this.loginErrorSub$.next([err.message]);
          }
          return throwError(err);
          // return EMPTY;
        })
      );
  }

  async initDataAfterLogin(res) {
    localStorage.removeItem('menus');
    const auth = JSON.parse(res);
    localStorage.setItem('jwt', auth.token);
    this.isLoginSub$.next(true);
    await new Promise<void>((resolve) => {
      this.getCurrentUser(resolve);
    })
    this.router.navigate(['trang-chu']);
    this.mapRouteService.initialize(true);
  }

  setMenuItems(menuItems: any) {
    this.menuItemsSub.next(menuItems);
  }

  async checkPermission(pageKey: string) {
    const permissions = this.permissionsSub.value;
    if (!permissions.functionManager.includes(pageKey)) {
      const firstPermission = this.menuItemsSub.value[0];
      if (firstPermission) {
        this.router.navigateByUrl(firstPermission.url);
        return;
      }
      this.logoutAndReload();
    }
  }

  register(input: RegisterStoreInput) {
    let response: any;
    const headers = new HttpHeaders({
      [TRANSACTION_NAME]: 'Register'
    });
    return this.httpClient
      .post(`${this.authUrl}/register-new-store`, input, { headers })
      .pipe(
        tap(result => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => {
          if (err.errorJson) {
            this.registerErrorSub$.next(err.errorJson.message);
          } else {
            this.registerErrorSub$.next([err.message]);
          }
          return throwError(err);
          // return EMPTY;
        })
      );
  }

  activeAccount(input: RegisterActiveInput) {
    let response: any;
    const headers = new HttpHeaders({
      [TRANSACTION_NAME]: 'Active account'
    });
    return this.httpClient
      .post(`${this.authUrl}/check-code-store-expired`, input, { headers })
      .pipe(
        tap(result => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => {
          if (err.errorJson) {
            this.activeErrorSub$.next(err.errorJson.message);
          } else {
            this.activeErrorSub$.next([err.message]);
          }
          return throwError(err);
          // return EMPTY;
        })
      );
  }

  isLogin() {
    return getJWT() != null;
  }

  isOnboard() {
    const jwt = getJWT();
    const token: TokenPayload = jwt_decode<TokenPayload>(jwt);
    if (token && token.isOnboard) {
      return token.isOnboard;
    }
    return false;
  }

  invalidateSession() {
    localStorage.removeItem('jwt');
    this.mapRouteService.clearBuildedMenus();
    this.isLoginSub$.next(false);
    this.loginErrorSub$.next([]);
    this.userSub$.next(null);
    this.userService.userInfo.next({});
    this.redirectUrl = '';
  }

  logout() {
    this.invalidateSession();
    const currentUrl = window.location.pathname;
    currentUrl !== '/auth/login' ? this.router.navigate(['/']) : '';
    localStorage.clear();
    this.closeSideNavSub$.next();
  }

  logoutAndReload() {
    this.invalidateSession();
    window.location.href = '/auth/login';
  }

  canAccess(code: any, objCode?: any, id?: any) {
    return isAuthorized(code, objCode, id);
  }

  getUser(): SKKNUserInfo | null {
    const userData = localStorage.getItem('currentUser');
    if (!userData) {
      return null;
    }
    const user = JSON.parse(userData);
    return user;
  }

  getCurrentUser(resolve?) {
    const userId = this.getUserIdFromToken();
    if (!userId) {
      return {};
    }
    this.getUserAsync().subscribe(res => {
      localStorage.setItem('currentUser', JSON.stringify(res));
      if (resolve) {
        resolve();
      }
    });
  }

  getUserIdFromToken(): string | null {
    const jwt = getJWT();
    if (!jwt) {
      return null;
    }
    const token: TokenPayload = jwt_decode<TokenPayload>(jwt);
    let id = '';
    Object.keys(token).map(function(key) {
      const arrSpl = key.split('/');
      if (
        arrSpl &&
        arrSpl.length > 0 &&
        arrSpl[arrSpl.length - 1] === 'userdata'
      ) {
        const userData = JSON.parse(token[key]);

        id = userData.userId;
      }
    });
    return id;
  }

  getExpiredTime() {
    const jwt = getJWT();
    if (!jwt) {
      return null;
    }
    const token: TokenPayload = jwt_decode<TokenPayload>(jwt);
    return token.exp;
  }

  checkAccess(url: string) {
    const headers = new HttpHeaders({
      [TRANSACTION_NAME]: 'Check access right'
    });
    const params = new HttpParams({ encoder: new CustomEncoder() }).set(
      'url',
      url
    );

    this.httpClient
      .get<boolean>(`${this.authUrl}/check-access`, { headers, params })
      .pipe(
        tap((result: boolean) => {
          Logger.log('checkAccess boolResult', result);
          this.checkAccessSub$.next(result);
        }),
        catchError(err => {
          Logger.log('checkAccess exception', err);
          this.checkAccessSub$.next(false);
          return EMPTY;
        })
      )
      .subscribe();
  }

  isBlockchainDisabled() {
    const headers = new HttpHeaders({
      [TRANSACTION_NAME]: 'Check whether or not blockchain is disabled'
    });

    this.httpClient
      .get<{ disabled: boolean }>(`${this.authUrl}/disable-blockchain`, {
        headers
      })
      .pipe(
        tap(({ disabled }) => {
          this.disableBlockchainSub$.next(disabled);
        }),
        catchError(err => {
          Logger.log('isBlockchainDisabled exception', err);
          this.disableBlockchainSub$.next(false);
          return EMPTY;
        })
      )
      .subscribe();
  }

  getNotification(): Observable<NotificationResult> {
    const headers = new HttpHeaders({
      [TRANSACTION_NAME]: 'Get all notification'
    });
    return this.httpClient
      .get<NotificationResult>(`${this.authUrl}/notification`, { headers })
      .pipe();
  }

  updateUserLanguage(userId: string, currentLanguageCode?: string) {
    const headers = new HttpHeaders({
      [TRANSACTION_NAME]: 'update user language'
    });
    const userI = { userId: userId, languageCode: currentLanguageCode };

    return this.httpClient
      .post(`${this.authUrl}/update-user-language`, userI, {
        headers,
        responseType: 'text'
      })
      .pipe(
        tap((jwt: any) => {
          localStorage.setItem('jwt', jwt);
          let user = this.getUser();
          this.userSub$.next(user);
        })
      )
      .subscribe();
  }

  getListUserOfAgent() {
    return this.httpClient.get(`${this.authUrl}/list-user`).pipe(
      tap(result => {
        return result;
      }),
      catchError((err: CustomHttpErrorResponse) => this.handleError(err))
    );
  }

  sendEmailVerification() {
    return this.httpClient
      .post(`${this.authUrl}/send-email-verification`, {})
      .pipe(
        tap((res: any) => {
          console.log(res);
        }),
        catchError((err: CustomHttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  verifyCode(code: string) {
    return this.httpClient
      .post(`${this.authUrl}/verify-email`, {
        code
      })
      .pipe(
        tap((res: any) => {
          console.log(res);
        }),
        catchError((err: CustomHttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  getUserAsync() {
    return this.httpClient
      .get(`${this.authUrl}/account-info`)
      .pipe(
        tap((res: any) => {
        }),
        catchError((err: CustomHttpErrorResponse) => {
          return throwError(err);
        })
      );
  }
}
