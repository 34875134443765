import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'values'})
export class ValuesPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      let result = Object.keys(value).filter(s => s !== '').map(s => ({key: parseInt(s), value: value[s]}));
      if ('' in value) { 
        result = [{key: null, value: value['']},... result];
      }      
      return result;

    }
    return [];
  }
}