import { Component, Input, OnInit } from "@angular/core";
import { orderBy } from "lodash";

@Component({
  selector: "review-information-table",
  templateUrl: "./review-information-table.component.html",
  styleUrls: ["./review-information-table.component.scss"]
})
export class ReviewInformationTableComponent implements OnInit {

  dataSource = [];
  listHDTP = [];
  listHDCM = [];

  @Input() set dataTable(value) {
    if (value) {
      const listHDTP = [];
      const listHDCM = [];
      value.forEach((item) => {
        if (item.isPass !== null) {
          item.point = !!item.isPass ? 'Đạt' : "Không đạt";
          listHDTP.push(item);
        } else {
          listHDCM.push(item);
        }
        return item;
      })
      this.listHDCM = listHDCM;
      this.listHDTP = listHDTP;
    }
  };

  displayedReviewColumns = ['index', 'reviewerName', 'comment', 'point'];
  constructor() { }

  ngOnInit(): void {

  }


}
