<div class="row">
  <div class="col-12 title-dialog">
    <span>Quên mật khẩu</span>
  </div>
  <div class="col-12 content-dialog" [formGroup]="forgetPasswordForm">
    <div class="row">
      <div class="col-12 form-element">
        <mat-form-field class="example-full-width">
          <mat-label>Tài khoản đăng nhập</mat-label>
          <input type="email" matInput value="" formControlName="email" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-12 footer-dialog">
    <button (click)="close()" class="btn-cancel space-right">Đóng</button>
    <button (click)="save()" class="btn-primary btn-add-or-edit space-left">
      Gửi yêu cầu
    </button>
  </div>
</div>
