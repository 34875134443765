import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { NgxsModule } from "@ngxs/store";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatStepperModule } from "@angular/material/stepper";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule, AuthService, CoreModule } from "./modules";
import { MenuFilter, MenuHasChild } from "./modules/shared/pipes";
import { PipeModule } from "./modules/shared/pipe.module";
import { LoadingModule } from "./modules/loading/loading.module";
import { MatTableModule } from "@angular/material/table";
import { MaterialModule } from "./modules/shared/material.module";
import { HomeComponent } from "./modules/home/home.component";
import { NgxPhotoEditorModule } from "ngx-photo-editor";
import { ToastrModule } from "ngx-toastr";
//import { PreviewComponent } from "./modules/administrator/events/preview/preview.component";



export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [AppComponent, MenuFilter, MenuHasChild, HomeComponent],
  imports: [
    MaterialModule,
    MatTableModule,
    LoadingModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    AuthModule.forRoot(environment.api),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MatFormFieldModule,
    MatStepperModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    PipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    NgbModule,
    NgxPhotoEditorModule,
    NgxSliderModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-center'
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private authService: AuthService) {
    this.authService.getCurrentUser();
  }
}
