<div class="swal2-popup swal2-modal swal2-icon-info swal2-show">
    <div class="swal2-header">
        <div class="swal2-icon swal2-icon-show swal2-warning d-flex" *ngIf="dialog.icon == 'warning'">
            <span class="swal2-x-mark">
        <span class="swal2-x-mark-line-left"></span>
            <span class="swal2-x-mark-line-right"></span>
            </span>
        </div>
        <div class="swal2-icon swal2-icon-show swal2-confirm d-flex" *ngIf="dialog.icon == 'confirm'">
            <span class="swal2-x-mark">
        <span class="swal2-x-mark-line-check"></span>
            </span>
        </div>
        <h2 class="swal2-title" *ngIf="dialog.icon == 'error'">
            Oops...
        </h2>
        <h2 class="cust-h2 cust-margin-bottom" *ngIf="dialog.title == 'Remove Feed'">
            {{dialog.title}}
        </h2>
    </div>
    <div class="swal2-content">
        <div id="swal2-content" class="swal2-html-container">
         <span  *ngIf="dialog.title != 'Remove Feed'">   {{ dialog.content }} </span>
        </div>
        <div id="swal2-content" class="swal2-html-container left-text bm-30">
          <span  *ngIf="dialog.title == 'Remove Feed'">  {{ dialog.content }} </span>
        </div>
        
        <h2 class="cust-h2 cust-margin-bottom" *ngIf="dialog.title == 'Remove Feed'">
            URL
        </h2>
                <input class="cust-mat-field-width cust-margin-bottom feed-input"
                  type="text"
                  autocomplete="off"
                  readonly *ngIf="dialog.title == 'Remove Feed'"
                  value="{{dialog.url}}"                 
                />

    </div>
    <div class="row bt-15">
        <div class="col">
        <button *ngIf="dialog.cancel" (click)="onClose()" type="button" class="swal2-confirm swal2-styled bg-color left-button-cancel">
      Cancel
    </button>
</div>
<div class="col">
    <button *ngIf="dialog.ok" (click)="onOk()" type="button" class="swal2-confirm swal2-styled right-button-remove">
  OK
</button>
    <button *ngIf="dialog.remove" (click)="onRemove()" type="button" class="swal2-confirm swal2-styled right-button-remove">
    Remove
    </button>
</div>    


</div>