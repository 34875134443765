
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class OfficeDocumentTypeService {

    officeDocumentApi = `${this.apiConstants.serviceEndpoint}/OfficeDocumentType`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getOfficeDocumentType(id: string) : Observable<any>{
        return this.http.get(`${this.officeDocumentApi}/get-office-document-type/${id}`);
    }

    getOfficeDocumentTypes(body: any) : Observable<any>{
        return this.http.post(`${this.officeDocumentApi}/get-office-document-types`, body);
    }

    getAllOfficeDocumentTypes() : Observable<any>{
        return this.http.get(`${this.officeDocumentApi}/get-all-office-document-types`);
    }

    createOfficeDocumentType(body: any) {
        return this.http.post(`${this.officeDocumentApi}/create-office-document-type`, body);
    }

    updateOfficeDocumentType(body: any) {
        return this.http.put(`${this.officeDocumentApi}/update-office-document-type/${body.id}`, body);
    }

    deleteOfficeDocumentType(id: string): Promise<any> {
        return this.http.delete(`${this.officeDocumentApi}/delete-office-document-type/${id}`).toPromise();
    }

    deleteOfficeDocumentTypes(body: string[]): Promise<any> {
        return this.http.post(`${this.officeDocumentApi}/delete-office-document-types`, body).toPromise();
    }
}
