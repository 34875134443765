import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent } from './dialog/dialog.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { DialogWarningComponent } from './dialog-warning/dialog-warning.component';
import { MatSelectFilterComponent } from './mat-select-filter/mat-select-filter.component';
import { DialogReleaseNotesComponent } from './dialog-release-notes/dialog-release-notes.component';
import { MaterialModule } from '../material.module';
import { DialogForgetPasswordComponent } from './dialog-forget-password/dialog-forget-password.component';
import { ReviewInformationTableComponent } from './review-information-table/review-information-table.component';
import { TreeOrganizationSelectComponent } from './tree-organization-select/tree-organization-select.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { DialogPreviewDocumentComponent } from './attachment/preview/preview.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';

@NgModule({
  declarations: [
    DialogComponent,
    CustomSelectComponent,
    DialogWarningComponent,
    MatSelectFilterComponent,
    DialogReleaseNotesComponent,
    DialogForgetPasswordComponent,
    ReviewInformationTableComponent,
    TreeOrganizationSelectComponent,
    AttachmentComponent,
    DialogPreviewDocumentComponent,
    FilterPanelComponent
  ],
  imports: [
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDocViewerModule
  ],
  exports: [
    CustomSelectComponent,
    DialogWarningComponent,
    MatSelectFilterComponent,
    ReviewInformationTableComponent,
    TreeOrganizationSelectComponent,
    AttachmentComponent,
    DialogPreviewDocumentComponent,
    FilterPanelComponent
  ]
})
export class ComponentsModule {}
