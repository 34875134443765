
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class InitiativeFieldService {

    initiativeFieldApi = `${this.apiConstants.serviceEndpoint}/InitiativeField`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getInitiativeField(id: string) : Observable<any>{
        return this.http.get(`${this.initiativeFieldApi}/get-initiative-field/${id}`);
    }

    getInitiativeFields(body: any) : Observable<any>{
        return this.http.post(`${this.initiativeFieldApi}/get-initiative-fields`, body);
    }

    createInitiativeField(body: any) {
        return this.http.post(`${this.initiativeFieldApi}/create-initiative-field`, body);
    }

    updateInitiativeField(body: any) {
        return this.http.put(`${this.initiativeFieldApi}/update-initiative-field/${body.id}`, body);
    }

    deleteInitiativeField(id: string): Promise<any> {
        return this.http.delete(`${this.initiativeFieldApi}/delete-initiative-field/${id}`).toPromise();
    }

    deleteInitiativeFields(body: string[]): Promise<any> {
        return this.http.post(`${this.initiativeFieldApi}/delete-initiative-fields`, body).toPromise();
    }
}
