import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { DialogReleaseNotesComponent } from '../../shared/components/dialog-release-notes/dialog-release-notes.component';
import { DialogForgetPasswordComponent } from '../../shared/components/dialog-forget-password/dialog-forget-password.component';

@Component({
  selector: 'auth-login-form',
  templateUrl: './auth-login-form.component.html',
  styleUrls: ['./auth-login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthLoginFormComponent implements OnInit {
  loginForm: FormGroup;
  hide = true;
  smallScreen: Observable<boolean>;
  isShowPassword = false;
  loginError$ = this.authService.loginError$;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.loginForm = this.fb.group({
      // email: ["", Validators.compose([Validators.required, Validators.email])],
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])]
    });
  }

  validateForm(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  trimValue(key: string) {
    let trimData = this.loginForm.controls[key].value;
    if (trimData) {
      trimData = trimData.trim();
    }
    this.loginForm.controls[key].setValue(trimData);
  }

  login() {
    this.loginForm.markAllAsTouched();
    if (!this.loginForm.valid) {
      return;
    }
    this.isLoading = true;
    const formData = this.loginForm.controls;
    const req = {
      username: formData.username.value,
      password: formData.password.value,
      terminateSession: true
    };
    this.authService.login(req).subscribe(
      () => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
    // this.router.navigate(["administrator"]);
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  forgetPassword() {
    this.dialog.open(DialogForgetPasswordComponent, {
      width: '25vw',
      height: '50vh'
    });
  }

  openDialogReleaseNotes() {
    this.dialog.open(DialogReleaseNotesComponent, {
      width: '25vw',
      height: '50vh'
    });
  }
}
