import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { cloneDeep } from "lodash";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CustomHttpErrorResponse } from "../..";
import { ApiConstant } from "../../types";
import { API_SHARED_TOKEN } from "../types";


@Injectable({
  providedIn: "root",
})
export class SaveDataService {
  url = `${this.apiConstant.endpoint}`;
  public dataNews = new BehaviorSubject<any>({});
  dataNews$ = this.dataNews.asObservable();
  // urlMidas = environment.api.url_midas;

  public loading = new BehaviorSubject(false);
  loading$ = this.loading.asObservable();

  // data store menu
  public dataStoreMenu = new BehaviorSubject<any>([]);
  dataStoreMenu$ = this.dataStoreMenu.asObservable();

  public dataStoreMenuSave = new BehaviorSubject<any>([]);
  dataStoreMenuSave$ = this.dataStoreMenuSave.asObservable();

  public dataGeneralSetting = new BehaviorSubject<any>({});
  dataGeneralSetting$ = this.dataGeneralSetting.asObservable();

  public homePageSetting = new BehaviorSubject<any>({});
  homePageSetting$ = this.homePageSetting.asObservable();

  public dataEditorNew = new BehaviorSubject<any>({});
  dataEditorNew$ = this.dataEditorNew.asObservable();

  public isClickedSaveChange = new BehaviorSubject<any>(null);
  isClickedSaveChange$ = this.isClickedSaveChange.asObservable();

  public dataBusinessSetup = new BehaviorSubject<any>([]);
  dataBusinessSetup$ = this.dataBusinessSetup.asObservable();

  public dataDocumentSetup = new BehaviorSubject<any>([]);
  dataDocumentSetup$ = this.dataDocumentSetup.asObservable();

  public dataImageGallery = new BehaviorSubject<any>([]);
  dataImageGallery$ = this.dataImageGallery.asObservable();

  public generalSetingForm = new BehaviorSubject<any>([]);
  generalSetingForm$ = this.generalSetingForm.asObservable();

  public saveHomepageSetting = new BehaviorSubject<boolean>(false);
  saveHomepageSetting$ = this.generalSetingForm.asObservable();

  public dataConfigFeedback = new BehaviorSubject<any>([]);
  dataConfigFeedback$ = this.dataConfigFeedback.asObservable();

  public saveSplashSetup = new BehaviorSubject<any>(false);
  saveSplashSetup$ = this.saveSplashSetup.asObservable();

  selectedStoreId = new BehaviorSubject<string>("");
  selectedStoreId$ = this.selectedStoreId.asObservable();

  public showButtonSaveAll = new BehaviorSubject<boolean>(true);
  showButtonSaveAll$ = this.showButtonSaveAll.asObservable();

  stores = new BehaviorSubject<any[]>([]);
  stores$ = this.stores.asObservable();

  constructor(
    @Inject(API_SHARED_TOKEN) private apiConstant: ApiConstant,
    private http: HttpClient,
    private matSnackBar: MatSnackBar,
    private router: Router,
  ) { }

  saveDataNews(data: Object) {
    this.dataNews.next(data);
  }

  async onSaveAll(_url: string) {
    let dataGeneralSetting: any;
    this.dataGeneralSetting.subscribe((res: any) => {
      dataGeneralSetting = res;
    });

    let homePageSetting: any = {};
    this.homePageSetting.subscribe((res: any) => {
      homePageSetting = res;
    });

    let dataBusiness: any = {};
    this.dataBusinessSetup.subscribe((res) => {
      dataBusiness = res;
    });

    let dataDocument: any = {};
    this.dataDocumentSetup.subscribe((res) => {
      dataDocument = res;
    });

    let galleryType: any = {};
    this.dataImageGallery.subscribe((res) => {
      galleryType = res;
    });

    let configFeedBack: any = {};
    this.dataConfigFeedback.subscribe((res) => {
      configFeedBack = res;
    });

    let splashSetup: any = {};
    this.saveSplashSetup.subscribe((res) => {

      splashSetup = res;
    });

    switch (_url) {
      case "/administrator/news":
        break;
      case "/administrator/general-settings":
        if (dataGeneralSetting.formValid) {
          this.generalSetingForm.next(true);
          this.saveGeneralSetting(dataGeneralSetting).subscribe(
            (_res: any) => {
              this.openSnackBar("Save change successfully!");
            },
            () => {
              this.openSnackBar("Save change failed!", "FAIL");
            }
          );
        } else {
          this.generalSetingForm.next(false);
        }
        break;
      case "/administrator/homepage-settings":
        let value = "";
        let validFeatureImages = false;
        let validBtnRedirect = false;
        let validActions = true;
        if (homePageSetting) {

          if (homePageSetting.featuredImage.enableFeaturedImage) {
            if (!homePageSetting.featuredImage.isEmptyImageLink &&
              !homePageSetting.featuredImage.isEmptyImage &&
              homePageSetting.featuredImage.featuredImageUrl &&
              homePageSetting.featuredImage.imageLink) {
              validFeatureImages = true;
            } else {
              if (!homePageSetting.featuredImage.featuredImageUrl) {
                homePageSetting.featuredImage.isEmptyImage = true
              }
              if (!homePageSetting.featuredImage.imageLink) {
                homePageSetting.featuredImage.isEmptyImageLink = true
              }
            }
          } else {
            validFeatureImages = true;
            homePageSetting.featuredImage.isEmptyImageLink = false
            homePageSetting.featuredImage.isEmptyImage = false
          }
          if (homePageSetting.btnRedirect.enableButton) {
            if (!homePageSetting.btnRedirect.isEmptyLink &&
              !homePageSetting.btnRedirect.isEmptyText &&
              !homePageSetting.btnRedirect.isEmptyDes &&
              homePageSetting.btnRedirect.buttonLink &&
              homePageSetting.btnRedirect.buttonText &&
              homePageSetting.btnRedirect.buttonDes) {
              validBtnRedirect = true;
            } else {
              if (!homePageSetting.btnRedirect.buttonLink) {
                homePageSetting.btnRedirect.isEmptyLink = true;
              }
              if (!homePageSetting.btnRedirect.buttonText) {
                homePageSetting.btnRedirect.isEmptyText = true;
              }
              if (!homePageSetting.btnRedirect.buttonDes) {
                homePageSetting.btnRedirect.isEmptyDes = true;
              }
            }
          } else {
            validBtnRedirect = true;
            homePageSetting.btnRedirect.isEmptyLink = false;
            homePageSetting.btnRedirect.isEmptyText = false;
            homePageSetting.btnRedirect.isEmptyDes = false;
          }

          if (homePageSetting.actions.isCheck) {

            var actions = homePageSetting.actions;
            if (actions.feature == 'selectFeature') {
              homePageSetting.actions.isEmptyFeature = true;
              validActions = false;
            }
            if (actions.config.numberOfTable <= 0) {
              validActions = false;
              homePageSetting.actions.config.isValidNumber = true;
            }
            if (actions.feature == 'callWaiter' || actions.feature == 'callBill') {
              if (!actions.config.text) {
                homePageSetting.actions.config.isEmptyText = true;
                validActions = false;
              }
            } else if (actions.feature == 'roomService') {
              if (!actions.config.text) {
                homePageSetting.actions.config.isEmptyText = true;
                validActions = false;
              }
              if (!actions.config.availableHour) {
                homePageSetting.actions.config.isEmptyAvailableHour = true;
                validActions = false;
              }
              homePageSetting.actions.config.serviceType.map((item: any) => {
                if (item.required && !item.value) {
                  item.isEmpty = true;
                  validActions = false;
                }
              })
            } else if (actions.feature == 'donations') {
              if (!actions.config.text) {
                homePageSetting.actions.config.isEmptyText = true;
                validActions = false;
              }
              if (!actions.config.url) {
                homePageSetting.actions.config.isEmptyUrl = true;
                validActions = false;
              }
            }
          }

          if (validBtnRedirect && validFeatureImages && validActions) {
            value = JSON.stringify(homePageSetting);
            let setting = {
              key: "homepage-settings",
              value: value,
            };
            this.saveGeneralSettingData(setting).subscribe(
              (_res: any) => {
                this.openSnackBar("Save change successfully!");
              },
              () => {
                this.openSnackBar("Save change failed!", "FAIL");
              }
            );
          } else {
            this.homePageSetting.next(homePageSetting);
            this.openSnackBar("Save change failed!", "FAIL");
          }
        } else {
          this.openSnackBar("Save change failed!", "FAIL");
        }
        break;
      case "/administrator/store-menu":
        let data: any = {};
        this.dataStoreMenu.subscribe((res) => {
          data = {
            menuContent: res.data,
            type: res.type
          };
        });
        let checkSave = true;
        let dataClone = cloneDeep(data);
        if (data?.menuContent.length > 0) {
          dataClone.menuContent.forEach((menu: any) => {
            menu.isEmptyMenuName = false;
            if (!menu.menuName || menu.menuName == "") {
              menu.isEmptyMenuName = true;
              checkSave = false;
            }
            menu.isEmptyPDF = false;
            if (
              menu.usePDFMenu &&
              (!menu.PDFMenuUrl || menu.PDFMenuUrl == "")
            ) {
              menu.isEmptyPDF = true;
              checkSave = false;
            }
            if (menu.category && menu.category.length > 0) {
              menu.category.forEach((category: any) => {
                category.isEmptyCategoryName = false;
                if (
                  (!category.categoryName || category.categoryName == "") &&
                  !menu.usePDFMenu
                ) {
                  category.isEmptyCategoryName = true;
                  checkSave = false;
                }
                if (category.menuItem && category.menuItem.length > 0) {
                  category.menuItem.forEach((item: any) => {
                    item.isEmptyItemText = false;
                    if (
                      (!item.itemText || item.itemText == "") &&
                      !menu.usePDFMenu
                    ) {
                      item.isEmptyItemText = true;
                      checkSave = false;
                    }
                    item.isEmptyImage = false;
                    if (
                      (!item.itemImageUrl || item.itemImageUrl == "") &&
                      !menu.usePDFMenu
                    ) {
                      item.isEmptyImage = true;
                      checkSave = false;
                    }
                  });
                }
              });
            }
          });
        }
        this.dataStoreMenuSave.next(dataClone.menuContent);
        if (checkSave) {
          this.saveStoreMenu(data).subscribe(
            (_res: any) => {
              this.openSnackBar("Save change successfully!");
            },
            () => {
              this.openSnackBar("Save change failed!", "FAIL");
            }
          );
        }
        break;
      case "/administrator/business-setup":
      case "/onboard":
        // if (dataBusiness) {
        //   dataBusiness.storeAddress.countryCode = "";
        //   dataBusiness.storeAddress.continent = "";
        //   dataBusiness.storeAddress.continentCode = "";
        //   dataBusiness.storeAddress.stateCode = "";
        //   dataBusiness.storeAddress.cityCode = "";

        //   let inputMapElement = document.getElementById(
        //     "location-map"
        //   ) as HTMLInputElement;
        //   if (inputMapElement) {
        //     dataBusiness.deviceCoordinates = inputMapElement.value;
        //   }
        //   let inputCityElement = document.getElementById(
        //     "location-city"
        //   ) as HTMLInputElement;
        //   if (inputCityElement) {
        //     dataBusiness.storeAddress.city = inputCityElement.value;
        //   }
        //   let inputStateElement = document.getElementById(
        //     "location-state"
        //   ) as HTMLInputElement;
        //   if (inputStateElement) {
        //     dataBusiness.storeAddress.state = inputStateElement.value;
        //   }
        //   let inputCountryElement = document.getElementById(
        //     "location-country"
        //   ) as HTMLInputElement;
        //   if (inputCountryElement) {
        //     dataBusiness.storeAddress.country = inputCountryElement.value;
        //   }

        //   let checkBusiness: boolean = true;
        //   if (!dataBusiness.companyName || dataBusiness.companyName == "") {
        //     dataBusiness.isErrorCompanyName = true;
        //     checkBusiness = false;
        //   } else {
        //     dataBusiness.isErrorCompanyName = false;
        //   }

        //   if (!dataBusiness.description || dataBusiness.description == "") {
        //     dataBusiness.isErrorDescription = true;
        //     checkBusiness = false;
        //   } else {
        //     dataBusiness.isErrorDescription = false;
        //   }

        //   if (!dataBusiness.thumbUrl || dataBusiness.thumbUrl == "") {
        //     dataBusiness.isErrorThumb = true;
        //     checkBusiness = false;
        //   } else {
        //     dataBusiness.isErrorThumb = false;
        //   }

        //   if (
        //     !dataBusiness.ownerName.firstName ||
        //     dataBusiness.ownerName.firstName == ""
        //   ) {
        //     dataBusiness.ownerName.isErrorFirstName = true;
        //     checkBusiness = false;
        //   } else {
        //     dataBusiness.ownerName.isErrorFirstName = false;
        //   }

        //   if (
        //     !dataBusiness.ownerName.lastName ||
        //     dataBusiness.ownerName.lastName == ""
        //   ) {
        //     dataBusiness.ownerName.isErrorLastName = true;
        //     checkBusiness = false;
        //   } else {
        //     dataBusiness.ownerName.isErrorLastName = false;
        //   }

        //   if (
        //     !dataBusiness.deviceCoordinates ||
        //     dataBusiness.deviceCoordinates == ""
        //   ) {
        //     dataBusiness.isErrorDeviceCoordinates = true;
        //     checkBusiness = false;
        //   } else {
        //     dataBusiness.isErrorDeviceCoordinates = false;
        //     let index = dataBusiness.deviceCoordinates.indexOf(",");
        //     if (index != -1) {
        //       let check = dataBusiness.deviceCoordinates.substring(index + 1);
        //       if (check && check.length > 0) {
        //         let latLong = dataBusiness.deviceCoordinates.split(",");
        //         if (latLong && latLong.length > 1) {
        //           let resAddress = await this.getAddressFromLatLongNew(
        //             +latLong[0],
        //             +latLong[1]
        //           );
        //           if (resAddress) {
        //             dataBusiness.storeAddress.continent = resAddress.continent;
        //             dataBusiness.storeAddress.continentCode =
        //               resAddress.continentCode;
        //             dataBusiness.storeAddress.countryCode =
        //               resAddress.countryCode;
        //             dataBusiness.storeAddress.stateCode =
        //               resAddress.principalSubdivisionCode;
        //             dataBusiness.storeAddress.cityCode = resAddress.postcode;
        //           }
        //         }
        //       }
        //     }
        //   }

        //   if (!dataBusiness.businessType || dataBusiness.businessType == "") {
        //     dataBusiness.isErrorBusinessType = true;
        //     checkBusiness = false;
        //   } else {
        //     dataBusiness.isErrorBusinessType = false;
        //   }

        //   if (!dataBusiness.currency || dataBusiness.currency == "") {
        //     dataBusiness.isErrorCurrency = true;
        //     // checkBusiness = false;
        //   } else {
        //     dataBusiness.isErrorCurrency = false;
        //   }

        //   this.dataBusinessSetup.next(dataBusiness);
        //   if (checkBusiness) {
        //     if (!dataBusiness.deviceNumber || dataBusiness.deviceNumber == "") {
        //       let body = {
        //         states: dataBusiness.storeAddress.state,
        //         city: dataBusiness.storeAddress.city,
        //       };
        //       this.getDeviceNumberMidas(body).subscribe(
        //         (_res: any) => {
        //           dataBusiness.deviceNumber = _res;
        //           dataBusiness.serialNumber = this.getSerialNumberBusiness(
        //             dataBusiness
        //           );
        //           this.saveDataBusiness(dataBusiness);
        //         },
        //         (err: any) => {
        //           console.log(err);
        //           this.openSnackBar("Save change failed!", "FAIL");
        //         }
        //       );
        //     } else {
        //       dataBusiness.serialNumber = this.getSerialNumberBusiness(
        //         dataBusiness
        //       );
        //       this.saveDataBusiness(dataBusiness);
        //     }
        //   }
        // }
        break;
      case "/administrator/document":
        if (dataDocument) {
          let checkDocument: boolean = true;
          if (
            dataDocument.lstMenuOptions &&
            dataDocument.lstMenuOptions.length > 0
          ) {
            dataDocument.lstMenuOptions.forEach((element) => {
              if (!element.categoryName || element.categoryName == "") {
                element.isEmptyCategoryName = true;
                checkDocument = false;
              } else {
                element.isEmptyCategoryName = false;
              }
              if (element.items && element.items.length > 0) {
                element.items.forEach((item) => {
                  if (!item.itemName || item.itemName == "") {
                    item.isEmptyItemName = true;
                    checkDocument = false;
                  } else {
                    item.isEmptyItemName = false;
                  }
                  if (!item.itemPdf || item.itemPdf == "") {
                    item.isEmptyItemPdf = true;
                    checkDocument = false;
                  } else {
                    item.isEmptyItemPdf = false;
                  }
                });
              }
            });
          }
          this.dataDocumentSetup.next(dataDocument);
          if (checkDocument) {
            let value = JSON.stringify(dataDocument);
            let setting = {
              key: "document-settings",
              value: value,
            };
            this.saveGeneralSettingData(setting).subscribe(
              (_res: any) => {
                this.openSnackBar("Save change successfully!");
              },
              () => {
                this.openSnackBar("Save change failed!", "FAIL");
              }
            );
          }
        }
        break;
      case "/administrator/feedback":
        if (configFeedBack) {
          var valid: boolean = true;
          configFeedBack.map((item: any) => {
            if (item.required && !item.value) {
              item.isEmpty = true;
              valid = false;
            }
          })
          if (!valid) {
            this.dataConfigFeedback.next(configFeedBack);
            this.openSnackBar("Save change failed!", "FAIL");
          } else {
            var dataFields: any[] = [];
            configFeedBack.map((item: any) => {
              if (item.value) {
                dataFields.push(item.value);
              }
            })
            this.saveFeedbackConfigData(dataFields).subscribe(
              (_res: any) => {
                this.openSnackBar("Save change successfully!");
              },
              () => {
                this.openSnackBar("Save change failed!", "FAIL");
              }
            );
          }
        }
        break;
      case "/administrator/splash-screen":
        if (splashSetup) {
          this.saveSplashOnServerFiles(splashSetup).subscribe(
            (_res: any) => {
              console.log(_res);
            },
            (_err: any) => {
              console.log(_err);
            }
          );
          if (splashSetup.id) {
            console.log(splashSetup);
            this.editSplash(splashSetup.id, splashSetup).subscribe(
              (_res: any) => {
                this.openSnackBar("Save change successfully!");
              },
              () => {
                this.openSnackBar("Save change failed!", "FAIL");
              }
            );

          } else {
            this.addSplash(splashSetup).subscribe(
              (_res: any) => {
                this.openSnackBar("Save change successfully!");
              },
              () => {
                this.openSnackBar("Save change failed!", "FAIL");
              }
            );
          }
        }
        break;
      case "/administrator/image-gallery":
        let body = {
          type: galleryType ?? "thumbnail",
          storeId: this.selectedStoreId.value,
        };
        this.saveGalleryType(body).subscribe(
          (_res: any) => {
            this.openSnackBar("Save change successfully!");
          },
          () => {
            this.openSnackBar("Save change failed!", "FAIL");
          }
        );
        break;
      default:
        break;
    }
  }

  saveDataBusiness(dataBusiness, storeId?: string, isClaim?: boolean) {
    // this.saveDataBusinessMidas(dataBusiness).subscribe((_res: any) => {
    //   if (_res) {
    //     dataBusiness.id = _res.data.id;
    //     dataBusiness.adsId = _res.data?.adsId;
    //     dataBusiness.adsKey = _res.data?.adsKey;
    //     dataBusiness.adsUrl = _res.data?.adsUrl;
    //     this.dataBusinessSetup.next(dataBusiness);
    //     let value = JSON.stringify(dataBusiness);
    //     let setting = {
    //       key: "business-settings",
    //       value: value,
    //     };

    //     this.saveGeneralSettingData(setting, storeId, isClaim).subscribe(
    //       (_res1: any) => {
    //         this.openSnackBar("Save change successfully!");
    //         this.loading.next(false);
    //         if (isClaim) {
    //           this.router.navigate(['administrator/claim-store']);
    //         }
    //       },
    //       () => {
    //         this.openSnackBar("Save change failed!", "FAIL");
    //         this.loading.next(false);
    //       }
    //     );
    //   } else {
    //     this.openSnackBar("Save change failed!", "FAIL");
    //     this.loading.next(false);
    //   }
    // });
  }

  getSerialNumberBusiness(dataBusiness: any) {
    let serial = "";
    if (dataBusiness) {
      if (
        dataBusiness.businessTypeName &&
        dataBusiness.businessTypeName != ""
      ) {
        serial += dataBusiness.businessTypeName.slice(0, 1).toUpperCase();
      }
      if (dataBusiness.storeAddress.continentCode) {
        serial += dataBusiness.storeAddress.continentCode;
      }
      if (dataBusiness.storeAddress.countryCode) {
        serial += dataBusiness.storeAddress.countryCode;
      }
      if (dataBusiness.storeAddress.stateCode) {
        serial += dataBusiness.storeAddress.stateCode;
      }
      if (dataBusiness.storeAddress.cityCode) {
        serial += dataBusiness.storeAddress.cityCode;
      }
      if (dataBusiness.companyName && dataBusiness.companyName != "") {
        if (dataBusiness.companyName.length >= 6) {
          serial += dataBusiness.companyName.slice(0, 6).toUpperCase();
        } else {
          let temp = dataBusiness.companyName.repeat(6);
          serial += temp.slice(0, 6).toUpperCase();
        }
      }
      if (dataBusiness.deviceNumber) {
        serial += dataBusiness.deviceNumber;
      }
    }
    return serial;
  }

  uploadFile(input: any) {
    let response: any;
    return this.http
      .post(`${this.url}/administrator/upload-file`, input, {
        responseType: "text",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  uploadFileDocument(input: any) {
    let response: any;
    return this.http
      .post(`${this.url}/administrator/upload-file-document`, input, {
        responseType: "text",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getDetailGeneralSettings(storeId: string) {
    let response: any;
    return this.http
      .get(`${this.url}/administrator/general-settings/${storeId}`)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  detailGeneralSettings(input: object, storeId?: string) {
    const params: any = {};
    if (storeId) {
      params.storeId = storeId;
    }
    let response: any;
    return this.http
      .post(`${this.url}/portal/detail-general-settings`, input, {
        params,
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getGeneralSettings(key: string, storeIdInput?: string) {
    const storeId = storeIdInput ? storeIdInput : this.selectedStoreId.value;
    if (storeId) {
      let response: any;
      return this.http
        .get(`${this.url}/administrator/get-setting-key/${storeId}?key=${key}`)
        .pipe(
          tap((result) => {
            response = result;
          }),
          map(() => {
            return response;
          }),
          catchError((err: CustomHttpErrorResponse) => throwError(err))
        );
    }
    let response: any;
    return this.http
      .get(`${this.url}/administrator/get-general-settings?key=${key}`)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  saveGeneralSetting(input: any) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/save-general-settings`, input, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  saveFeedbackConfigData(input: any) {
    let response: any;
    const storeId = this.selectedStoreId.value;
    const body = {
      fieldname: input,
      storeid: storeId
    }
    console.log(storeId)
    return this.http.post(`${this.url}/administrator/save-feedbackfields`, body, {
      responseType: "json",
    })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  saveGeneralSettingData(input: any, storeId?: string, isClaim?: boolean) {
    const id = storeId ?? this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/save-general-settings-data`, input, {
        responseType: "json",
        params: {
          storeId: id,
          isClaim: isClaim
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getAllCategories(type: string) {
    let response: any;
    return this.http
      .post(`${this.url}/portal/all-categories`, { type: type })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getAllNews(storeId?: string) {
    let response: any;
    return this.http
      .get(`${this.url}/administrator/all-news?storeId=${storeId}`)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getAllFeeds() {
    let response: any;
    const storeId = this.selectedStoreId.value;
    return this.http.get(`${this.url}/portal/all-feeds?storeId=` + storeId).pipe(
      tap((result) => {
        response = result;
      }),
      map(() => {
        return response;
      })
    );
  }

  getSplashSetup() {
    let response: any;
    const storeId = this.selectedStoreId.value;

    return this.http.get(`${this.url}/portal/all-splash?storeId=` + storeId).pipe(
      tap((result) => {
        response = result;
      }),
      map(() => {
        return response;
      })
    );
  }

  getLstRssFeeds() {
    let response: any;
    const storeId = this.selectedStoreId.value;
    return this.http.get(`${this.url}/administrator/all-rss-feeds?storeId=` + storeId).pipe(
      tap((result) => {
        response = result;
      }),
      map(() => {
        return response;
      })
    );
  }

  getDetailNew(idNew: any): Promise<any> {
    let response: any;
    return this.http
      .get(`${this.url}/portal/detail-news/${idNew}`, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        })
      )
      .toPromise();
  }

  addNews(input: any) {
    let response: any;
    return this.http
      .post(`${this.url}/administrator/add-news`, input, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  addFeeds(input: any) {
    const storeId = this.selectedStoreId.value;
    input.storeId = storeId;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/add-feeds`, input, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }


  addSplash(input: any) {
    const storeId = this.selectedStoreId.value;
    input.storeId = storeId;
    console.log(input);
    console.log('--end----')
    let response: any;

    return this.http
      .post(`${this.url}/administrator/add-splash`, input, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          console.log(result);
          response = result;
        }),
        map(() => {
          console.log(response);
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  addRSSFeeds(input: any) {
    const storeId = this.selectedStoreId.value;
    input.storeId = storeId;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/add-rss-feeds`, input, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  createCategory(input: any) {
    let response: any;
    return this.http
      .post(`${this.url}/administrator/add-categories`, input)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  editCategory(id: string, input: any) {
    let response: any;
    return this.http
      .post(`${this.url}/administrator/edit-categories/${id}`, input)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  editSplash(id: string, input: any) {
    const storeId = this.selectedStoreId.value;
    input.storeId = storeId;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/edit-splash/${id}`, input)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  saveSplashOnServerFiles(input: any) {

    let url = 'https://monitor.minimeghost.com/auth/splash_editor.php?logo=' + input.logo + '&btncolor=' + input.btncolor.replace('#', '') + '&primarycolor=' + input.primarycolor.replace('#', '') + '&btntextcolor=' + input.btntextcolor.replace('#', '') + '&bgimage=' + input.bgimage.replace('#', '') + '&accentcolor=' + input.accentcolor.replace('#', '') + '&termcondition=' + input.termcondition + '&macAddress=' + input.macAddress + '&fwd2=' + input.frwdurl2 + '&ssid2=' + input.ssid2 + '&splashid=' + input.splashID + ''
    console.log(encodeURI(url));
    let response: any;
    return this.http
      .get(url)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  deleteCategory(id: string) {
    let response: any;
    return this.http
      .delete(`${this.url}/administrator/delete-categories/${id}`)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getCategory(name: string) {
    let response: any;
    return this.http
      .get(`${this.url}/portal/get-category/${name}`)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getAllImageGallery() {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .get(`${this.url}/administrator/list-gallery-image`, {
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        })
      );
  }

  uploadImageGallery(input: any) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/upload-gallery-image`, input, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  changeStatusGallery(input: any) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/change-status-gallery-image`, input, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  removeImageGallery(input: any) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/remove-gallery-image`, input, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  updateNews(input: any) {
    let response: any;
    return this.http
      .post(`${this.url}/administrator/edit-news/${input.id}`, input, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  removeNews(input: any) {
    let response: any;
    return this.http
      .delete(`${this.url}/administrator/delete-news/${input.id}`, {
        responseType: "text",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  removeFeeds(input: any) {
    let response: any;
    return this.http
      .delete(`${this.url}/administrator/delete-feeds/${input.id}`, {
        responseType: "text",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  deleteRSSFeeds(input: any) {
    let response: any;
    return this.http
      .post(`${this.url}/administrator/delete-rss-feeds`, input, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  openSnackBar(message: string, status: string = "SUCCESS") {
    let className =
      status == "SUCCESS"
        ? "messenger-success"
        : status == "WANRING"
          ? "messenger-waning"
          : "messenger-fail";

    this.matSnackBar.open(message, "", {
      horizontalPosition: "end",
      verticalPosition: "top",
      duration: 3000,
      panelClass: className,
    });
  }

  getAllStoreMenu() {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .get(`${this.url}/administrator/detail-store-menu`, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        })
      );
  }

  saveStoreMenu(_data: any) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/save-store-menu`, _data, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getPromotion(cid: string) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(
        `${this.url}/portal/detail-promotions`,
        { idUser: cid },
        {
          params: {
            storeId,
          },
        }
      )
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  savePromotion(body: any) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/save-promotions`, body, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getListFeedBack(input: any) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .get(
        `${this.url}/administrator/list-feedback?page=${input.pages}&limit=${input.limit}`,
        {
          params: {
            page: input.pages,
            limit: input.limit,
            storeId,
          },
          responseType: "json",
        }
      )
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getListConfigFeedBack() {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .get(
        `${this.url}/administrator/list-config-feedback`,
        {
          params: {
            storeId,
          },
          responseType: "json",
        }
      )
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getAllFeedbackByUser() {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .get(`${this.url}/administrator/get-all-feedback-by-user`, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getSpecials(cid: string) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(
        `${this.url}/portal/detail-special`,
        { idUser: cid },
        {
          params: {
            storeId,
          },
        }
      )
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  saveSpecials(body: any) {
    const storeId = this.selectedStoreId.value;
    let response: any;
    return this.http
      .post(`${this.url}/administrator/save-special`, body, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  async submitNotification(payload: FormData) {
    try {
      const storeId = this.selectedStoreId.value;
      await this.http
        .post(`${this.url}/administrator/notifications/far`, payload, {
          params: {
            storeId,
          },
        })
        .toPromise();
      this.openSnackBar("Sent notification successfully!");
    } catch (err) {
      this.openSnackBar("Sent notification failed!", "FAIL");
    }
  }

  getAllCallWaiter(storeId: string) {
    let response: any;
    return this.http
      .get(`${this.url}/administrator/get-all-call-waiter`, {
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  deleteAllCallWaiter(storeId: string) {
    let response: any;
    return this.http
      .get(`${this.url}/administrator/delete-all-call-waiter`, {
        params: {
          storeId,
        },
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  closeCallWaiterById(id: string) {
    let response: any;
    return this.http
      .delete(`${this.url}/administrator/close-call-waiter/${id}`)
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  getAddressFromLatLong(lat: number, long: number) {
    let response: any;
    return this.http
      .get(
        `https://nominatim.openstreetmap.org/reverse?format=json&accept-language=en&lon=${long}&lat=${lat}`
      )
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  async getAddressFromLatLongNew(lat: number, long: number): Promise<any> {
    try {
      return await this.http
        .get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=en`
        )
        .toPromise();
    } catch (err) {
      this.openSnackBar("Error get address from lat long!", "FAIL");
    }
  }

  // getDataBusinessMidas() {
  //   let response: any;
  //   return this.http.get(`${this.urlMidas}/business/data-business-setup`).pipe(
  //     tap((result) => {
  //       response = result;
  //     }),
  //     map(() => {
  //       return response;
  //     }),
  //     catchError((err: CustomHttpErrorResponse) => throwError(err))
  //   );
  // }

  // getDeviceNumberMidas(body: any) {
  //   let response: any;
  //   return this.http
  //     .post(`${this.urlMidas}/business/generate-device-no`, body, {
  //       responseType: "text",
  //     })
  //     .pipe(
  //       tap((result) => {
  //         response = result;
  //       }),
  //       map(() => {
  //         return response;
  //       }),
  //       catchError((err: CustomHttpErrorResponse) => throwError(err))
  //     );
  // }

  // uploadFileMidas(input: any) {
  //   let response: any;
  //   return this.http
  //     .post(`${this.urlMidas}/business/upload-file`, input, {
  //       responseType: "text",
  //     })
  //     .pipe(
  //       tap((result) => {
  //         response = result;
  //       }),
  //       map(() => {
  //         return response;
  //       }),
  //       catchError((err: CustomHttpErrorResponse) => throwError(err))
  //     );
  // }

  // saveDataBusinessMidas(body: any) {
  //   let response: any;
  //   return this.http
  //     .post(`${this.urlMidas}/business/save-business`, body, {
  //       responseType: "json",
  //     })
  //     .pipe(
  //       tap((result) => {
  //         response = result;
  //       }),
  //       map(() => {
  //         return response;
  //       }),
  //       catchError((err: CustomHttpErrorResponse) => throwError(err))
  //     );
  // }

  updateEventOption(payload: any) {
    const storeId = this.selectedStoreId.value;
    return this.http
      .post(`${this.url}/administrator/event-options`, payload, {
        params: {
          storeId,
        },
      })
      .toPromise();
  }

  getEventOption() {
    const storeId = this.selectedStoreId.value;
    return this.http
      .get(`${this.url}/administrator/event-options`, {
        params: {
          storeId,
        },
      })
      .toPromise();
  }

  saveDataOnboard(body: any) {
    let response: any;
    return this.http
      .post(`${this.url}/administrator/save-data-onboard`, body, {
        responseType: "text",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  createNewStore(payload: any) {
    const storeId = this.selectedStoreId.value;
    return this.http
      .post(`${this.url}/administrator/create-sub-store`, payload, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .toPromise<any>();
  }

  deleteSubStore(storeId: string) {
    return this.http
      .delete(`${this.url}/administrator/sub-stores/${storeId}`)
      .toPromise();
  }

  getAllSubStores() {
    const storeId = this.selectedStoreId.value;
    return this.http
      .get(`${this.url}/administrator/sub-stores`, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .toPromise<any>();
  }

  async getAllStores() {
    const storeId = this.selectedStoreId.value;
    const stores = await this.http
      .get(`${this.url}/administrator/stores`, {
        responseType: "json",
        params: {
          storeId,
        },
      })
      .toPromise<any>();
    this.stores.next(stores);
    return stores;
  }

  saveGalleryType(payload: any) {
    let response;
    return this.http
      .post(`${this.url}/administrator/save-gallery-type`, payload, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  // GetRssFeedData(RssfeedURL) {

  //   const requestOptions: Object = {
  //     observe: "body",
  //     responseType: "text",
  //   };
  //   const encodeUrl = encodeURIComponent(RssfeedURL);

  //   const fURL = environment.corsProxy + "?url=" + encodeUrl;
  //   return this.http.get(fURL, requestOptions);
  // }

  async getBooingStore(numberStart: number, pageSize: number) {
    const storeId = this.selectedStoreId.value;
    const bookings = await this.http
      .get(`${this.url}/administrator/get-booking-store?storeId=${storeId}&numberStart=${numberStart}&pageSize=${pageSize}`, {
        responseType: "json",
      })
      .toPromise<any>();
    return bookings;
  }

  getClaimStore() {
    return this.http
      .get(`${this.url}/administrator/get-claim-store`, {
        responseType: "json",
      })
      .toPromise<any>();
  }

  detailGeneralSettingById(storeId: string) {
    let response: any;
    return this.http
      .get(`${this.url}/administrator/general-settings/${storeId}`)
      .pipe(
        tap(result => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }
  
  getSubStores(storeId: string) {
    let response: any;
    return this.http
      .get(`${this.url}/administrator/sub-stores-by-primary-store?storeId=${storeId}`)
      .pipe(
        tap(result => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  createUserSubStore(input: any, storeId: string){
    let response;
    return this.http
      .post(`${this.url}/administrator/sub-stores/user?storeId=${storeId}`, input, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }

  updateActionUserSubStore(input: any, storeId: string){
    let response;
    return this.http
      .put(`${this.url}/administrator/sub-stores/user?storeId=${storeId}`, input, {
        responseType: "json",
      })
      .pipe(
        tap((result) => {
          response = result;
        }),
        map(() => {
          return response;
        }),
        catchError((err: CustomHttpErrorResponse) => throwError(err))
      );
  }
}
