import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoneyFormatPipe, NumberFormatPipe, MomentPipe } from './pipes';
import { ValuesPipe } from './pipes/values.pipe';

@NgModule({
    declarations: [NumberFormatPipe, MoneyFormatPipe, MomentPipe, ValuesPipe],
    imports: [CommonModule],
    exports: [NumberFormatPipe, MoneyFormatPipe, MomentPipe, ValuesPipe],
})
export class PipeModule {}
