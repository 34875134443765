import { Router } from '@angular/router';
import { AuthService } from '../services';
import { SKKNUserInfo } from './../../core/types';
import { Component, OnInit } from '@angular/core';
import { CustomSnackBarService } from '../../shared/utils/custom-snack-bar.service';

@Component({
  selector: 'app-auth-verify-code',
  templateUrl: './auth-verify-code.component.html',
  styleUrls: ['./auth-verify-code.component.scss']
})
export class AuthVerifyCodeComponent implements OnInit {
  arrayInput: number[] = Array.from(Array(5).keys());

  user: SKKNUserInfo;
  isSend: boolean = false;
  timeout;
  intervalCountTime;
  loading = false;
  errorMsg = '';
  verificatioCode;
  constructor(
    private auth: AuthService,
    private router: Router,
    private _snackBar: CustomSnackBarService
  ) {}

  ngOnInit(): void {
    this.user = this.auth.getUser();
    if (this.user.emailVerified) {
      this.goback();
    }
  }

  focusNext(e: KeyboardEvent, i: any) {
    e.preventDefault();
    if (e.key.includes('Backspace')) {
      let prevElementSiblingId = i - 1;

      if (i > 0) {
        (document.getElementById(
          `${prevElementSiblingId}`
        ) as HTMLInputElement).focus();
      }
    } else {
      let nextElementSiblingId = i + 1;

      if (i < 6) {
        (document.getElementById(
          `${nextElementSiblingId}`
        ) as HTMLInputElement).focus();
      }
      this.arrayInput[i] = (e.target as any).value;
    }
  }

  sendCode() {
    if (this.isSend || this.timeout || this.loading) {
      return;
    }
    this.loading = true;
    this.auth.sendEmailVerification().subscribe(
      res => {
        this.isSend = true;
        this.loading = false;
        this.errorMsg = '';
        setTimeout(() => {
          (document.getElementById(`0`) as HTMLInputElement).focus();
        }, 100);
      },
      error => {
        this.loading = false;
        this.errorMsg = 'Gửi mã xác thực thất bại, vui lòng thử lại sau';
      }
    );
    this.countTime();
  }

  verifyCode() {
    if (!this.isSend || this.loading) {
      return;
    }
    this.loading = true;
    if (!this.verificatioCode || !this.verificatioCode?.trim()) {
      return;
    }
    this.auth.verifyCode(this.verificatioCode).subscribe(
      res => {
        if (res.isSucceeded) {
          this.auth.getCurrentUser();
          setTimeout(() => {
            this.isSend = false;
            this.loading = false;
            this.errorMsg = '';
            this._snackBar.success('Xác thực tài khoản thành công !');
            this.goback();
          }, 200);
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

  countTime() {
    this.timeout = 120;
    this.intervalCountTime = setInterval(() => {
      if (this.timeout === 0) {
        clearInterval(this.intervalCountTime);
        return;
      } else {
        this.timeout -= 1;
      }
    }, 1000);
  }

  goback() {
    this.router.navigate(['/trang-chu']);
  }
}
