
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_TOKEN } from "../../auth";
import { ApiConstant } from "../../types";

@Injectable({
    providedIn: "root"
})
export class PortalMenuService {

    portalMenuApi = `${this.apiConstants.serviceEndpoint}/PortalMenu`;

    constructor(
        @Inject(API_TOKEN) private apiConstants: ApiConstant,
        private http: HttpClient
    ) { }

    getPortalMenu(id: string) : Observable<any>{
        return this.http.get(`${this.portalMenuApi}/get-portal-menu/${id}`);
    }

    getPortalMenus(body: any) : Observable<any>{
        return this.http.post(`${this.portalMenuApi}/get-portal-menus`, body);
    }

    createPortalMenu(body: any) {
        return this.http.post(`${this.portalMenuApi}/create-portal-menu`, body);
    }

    updatePortalMenu(body: any) {
        return this.http.put(`${this.portalMenuApi}/update-portal-menu/${body.id}`, body);
    }

    deletePortalMenu(id: string): Promise<any> {
        return this.http.delete(`${this.portalMenuApi}/delete-portal-menu/${id}`).toPromise();
    }

    deletePortalMenus(body: string[]): Promise<any> {
        return this.http.post(`${this.portalMenuApi}/delete-portal-menus`, body).toPromise();
    }
}
