import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomSnackBarService } from '../utils/custom-snack-bar.service';
import { CommonMessage } from '../constants/common-message';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private _snackBar: CustomSnackBarService) { }


    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const useLocalHandleException = req.headers.get('useLocalHandleException');
        if (useLocalHandleException) {
            req = req.clone({
                headers: req.headers.delete('useLocalHandleException')
            });
        }
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err && err.status === 401) {
                    this.router.navigate(['/auth', 'login']);
                    this._snackBar.error(CommonMessage.tokenHasExpired);
                    return of(null);
                } else if (!useLocalHandleException) {
                    let message = CommonMessage.errorMessageBackend;
                    if (err && err.error) {
                        const errors = typeof err.error === 'string' ? JSON.parse(err.error) : err.error;
                        if (!errors.isSucceeded) {
                            message = errors.error;
                        }
                    }
                    this._snackBar.error(message);
                }
                throw err;
            })
        );
    }
}
