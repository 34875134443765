const localEndpoint = 'https://staging-identity.welete.com';
const serviceEndpoint = 'https://staging-module.welete.com/api';

export const environment = {
  production: true,
  hmr: false,
  api: {
    endpoint: localEndpoint,
    serviceEndpoint: serviceEndpoint,
  },
  elasticAPM: {
    serviceName: '',
    serverUrl: '',
    distributedTracingOrigins: [localEndpoint],
    transactionName: 'Transaction-Name',
    sensitiveParamNames: [
      'password',
      'currentPassword',
      'newPassword',
      'confirmPassword',
    ],
  },
  enableLogging: false,
  findOutMoreUrl: '',
  enableIomTermsAndConditions: false,
  closeDialogAfterRequestFail: false,
};
