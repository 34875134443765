<div class="row table-list" *ngIf="displayColumns.length">
  <div class="col-12">
    <div class="mat-elevation-z8 table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="index">
          <th class="pr-1" mat-header-cell *matHeaderCellDef>STT</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            style="width: 50px;"
          >
            {{ i + 1 }}
          </td>
        </ng-container>

        <ng-container
          *ngFor="let column of columnsData"
          [matColumnDef]="column.dataIndex"
        >
          <th mat-header-cell *matHeaderCellDef [style.width]="column.width">
            {{ column.display }}
          </th>

          <td mat-cell *matCellDef="let row" [style.width]="column.width">
            <div *ngIf="!column.isSpecial">
              {{ get(row, column.customDataIndex || column.dataIndex) }}
            </div>
            <ng-container
              *ngIf="column.isSpecial && tableName === 'evaluation'"
            >
              <div *ngIf="column.dataIndex === 'authorName'">
                <p>{{ row.authorName }}</p>
                <p *ngFor="let member of row.initiativeMembers">
                  {{ member.fullName }}
                </p>
              </div>
              <div *ngIf="column.dataIndex === 'position'">
                <p>{{ row.position }}</p>
                <p *ngFor="let member of row.initiativeMembers">
                  {{ member.position }}, {{ member.address }}
                </p>
              </div>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayColumns.length">
            Không tồn tại bản ghi nào
          </td>
        </tr>
      </table>

      <!-- <mat-paginator
        class="table-footer"
        [pageSize]="limit"
        [pageSizeOptions]="pageSizeOption"
        [length]="totalElement"
        (page)="onPageChange($event)"
        [pageIndex]="page - 1"
        [showFirstLastButtons]="true"
      ></mat-paginator> -->
    </div>
  </div>
</div>
